const THREE = require('three');

const Orbit = function () {
  const geom = new THREE.Object3D();

  this.mesh = geom;
  // this.mesh.add(sun);
};

export default Orbit;
