var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "fade" },
      on: {
        "before-enter": _vm.beforeEnter,
        "after-enter": _vm.afterEnter,
        leave: _vm.leave,
        "after-leave": _vm.afterLeave
      }
    },
    [
      _vm.isActive
        ? _c("div", { staticClass: "cm-level" }, [
            _c("div", { staticClass: "cm-level__picture" }, [
              _c("div", { staticClass: "cm-postcard" }, [
                _c("a", { attrs: { href: _vm.link, target: "_blank" } }, [
                  _c("div", { staticClass: "cm-postcard__media" }, [
                    _c("img", {
                      staticClass: "cm-postcard__picture",
                      attrs: { src: _vm.picture, alt: "" }
                    }),
                    _vm._v(" "),
                    _c("canvas", { attrs: { id: "snow-canvas" } })
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "cm-postcard__link" }, [
                    _vm._v("scopri tutti i viaggi " + _vm._s(_vm.label))
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cm-level__text" }, [
              _c(
                "div",
                {
                  staticClass:
                    "cm-lettering cm-level__message cm-level__message-level js-message"
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("level_city", { destination: _vm.label })) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "cm-lettering cm-level__message cm-level__message-city js-message"
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("level_km", {
                          distance: _vm.distance,
                          level: _vm.level
                        })
                      ) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cm-button cm-level__button",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleStart($event)
                    }
                  }
                },
                [_vm._v("\n      vai al prossimo livello\n    ")]
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }