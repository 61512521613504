/* global App */
import Vuex from 'vuex';
import Vue from 'vue';

import { getAllCities } from '../helpers/Cities';

Array.prototype.randsplice = function(){
    var ri = Math.floor(Math.random() * this.length);
    var rs = this.splice(ri, 1);
    return rs;
}

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    time: null,
    distance: 0,
    energy: 100,
    points: 0,
    level: 1,
    status: 'start',
    // status: 'level',
    connected: false,
    error: '',
    message: '',
    cameraMode: 'firstPerson',
    gameMode: 'mouse',
    orientation: {},
    sound: true,
    logo: App.LOGO,
    whatsappLink: App.WHATSAPP_LINK,
    siteLink: App.SITE_LINK,
    isMobile: (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1),
    destinations: getAllCities(),
    destinationsBuffer: getAllCities(),
    destinationCurrent: {
      id: 'europa',
      label: 'Europa',
      picture: 'europa.jpg',
    },
  },
  mutations: {
    setTime(state, data) {
      state.time = data;
    },
    setDestinationsBuffer(state) {
      state.destinationsBuffer = getAllCities();
    },
    setDestinationCurrent(state, data) {
      state.destinationCurrent = data;
    },
    setDistance(state, data) {
      state.distance = data;
    },
    setEnergy(state, data) {
      state.energy = data;
    },
    setPoints(state, data) {
      state.points = data;
    },
    setLevel(state, data) {
      state.level = data;
    },
    setStatus(state, data) {
      state.status = data;
    },
    setSound(state, data) {
      state.sound = data;
    },
    setCameraMode(state, data) {
      state.cameraMode = data;
    },
    setOrientation(state, data) {
      state.orientation = data;
    },
    SOCKET_CONNECT(state) {
      state.connected = true
    },
    SOCKET_DISCONNECT(state) {
      state.connected = false
    },
    SOCKET_MESSAGE(state, message) {
      state.message = message
    },
    SOCKET_GAME(state, message) {
      state.status = 'playing';
    },
    SOCKET_ORIENTATION(state, message) {
      state.orientation = message;
    },
    SOCKET_UID(state, message) {
      state.message = message
    },
    SOCKET_ERROR(state, message) {
      state.error = message.error
    },
  },
  actions: {
    setDestination(store) {
      const dest = store.state.destinations.randsplice()[0];
      store.commit('setDestinationCurrent', dest);
    },
    setStatus(store, status) {
      store.commit('setStatus', status);
    },
    reset(store) {
      store.commit('setDestinationsBuffer');
    },
  },
  getters: {
    time: state => state.time,
    distance: state => state.distance,
    energy: state => state.energy,
    points: state => state.points,
    level: state => state.level,
    status: state => state.status,
    sound: state => state.sound,
    cameraMode: state => state.cameraMode,
    orientation: state => state.orientation,
    logo: state => state.logo,
    whatsappLink: state => state.whatsappLink,
    siteLink: state => state.siteLink,
    isMobile: state => state.isMobile,
    destination: (state) => {
      console.log(state.destinationCurrent);
      return state.destinationCurrent;
    },
    destinationsBuffer: (state) => {
      return state.destinationsBuffer;
    }
  },
});

export default store;
