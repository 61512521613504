// Init Christmas! \o/
//
//
import $ from 'jquery';

const flakes = [];
let canvas;
let ctx;
let mX;
let mY;
let flakeCount;
let rafID = null;

let $container;

function snow() {
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  for (let i = 0; i < flakeCount; i++) {
    const flake = flakes[i];


    const x = mX;
    const y = mY;


    const minDist = 250;


    const x2 = flake.x;


    const y2 = flake.y;

    const dist = Math.sqrt((x2 - x) * (x2 - x) + (y2 - y) * (y2 - y));


    const dx = x2 - x;


    const dy = y2 - y;

    if (dist < minDist) {
      const force = minDist / (dist * dist);


      const xcomp = (x - x2) / dist;


      const ycomp = (y - y2) / dist;

      // deltaV = force / 2;

      const deltaV = force;

      flake.velX -= deltaV * xcomp;
      flake.velY -= deltaV * ycomp;
    } else {
      flake.velX *= 0.98;
      if (flake.velY <= flake.speed) {
        flake.velY = flake.speed;
      }
      flake.velX += Math.cos(flake.step += 0.05) * flake.stepSize;
    }

    ctx.fillStyle = `rgba(255,255,255,${flake.opacity})`;
    flake.y += flake.velY;
    flake.x += flake.velX;

    if (flake.y >= canvas.height || flake.y <= 0) {
      reset(flake);
    }

    if (flake.x >= canvas.width || flake.x <= 0) {
      reset(flake);
    }

    ctx.beginPath();
    ctx.arc(flake.x, flake.y, flake.size, 0, Math.PI * 2);
    ctx.fill();
  }
  rafID = requestAnimationFrame(snow);
}

function reset(flake) {
  flake.x = Math.floor(Math.random() * canvas.width);
  flake.y = 0;
  flake.size = (Math.random() * 2);
  flake.speed = (Math.random() * 1) + 0.5;
  flake.velY = flake.speed;
  flake.velX = 0;
  flake.opacity = (Math.random() * 0.5) + 0.3;
}

function init() {
  $container = $('.cm-postcard__media');
  canvas = document.getElementById('snow-canvas');
  ctx = canvas.getContext('2d');
  mX = -100;
  mY = -100;
  flakeCount = 450;
  if ($container.width() < 999) {
    flakeCount = 125;
  }
  canvas.width = $container.width();
  canvas.height = $container.height();
  for (let i = 0; i < flakeCount; i += 1) {
    const x = Math.floor(Math.random() * canvas.width);


    const y = Math.floor(Math.random() * canvas.height);


    const size = (Math.random() * 3);


    const speed = (Math.random() * 1) + 0.5;


    const opacity = (Math.random() * 0.5) + 0.3;

    flakes.push({
      speed,
      velY: speed,
      velX: 0,
      x,
      y,
      size,
      stepSize: (Math.random()) / 160,
      step: 0,
      opacity,
    });
  }
  // canvas.addEventListener('mousemove', (e) => {
  //   mX = e.clientX,
  //   mY = e.clientY;
  // });

  window.addEventListener('resize', () => {
    canvas.width = $container.innerWidth();
    canvas.height = $container.innerHeight();
  });

}

function play() {
  console.log('play');
  if (!canvas) {
    init();
  }
  snow();
}

function stop() {
  console.log('stop');
  cancelAnimationFrame(rafID);
  canvas = null;
}


export default {
  play,
  stop
};
