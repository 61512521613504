var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "co-intro",
      class: {
        "co-intro--is-guide": _vm.status == "guide",
        "co-intro--is-playing": _vm.status == "playing"
      }
    },
    [
      _c(
        "div",
        { staticClass: "co-intro__container-top" },
        [
          _c("div", { staticClass: "co-intro__logo" }, [
            _c("a", { attrs: { href: _vm.siteLink, target: "_blank" } }, [
              _c("img", {
                staticClass: "co-intro__logo-image",
                attrs: { src: _vm.logo }
              })
            ])
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { appear: "", name: "greatings-fade" } }, [
            _vm.isGreatingsActive
              ? _c(
                  "div",
                  {
                    staticClass: "co-intro__greatings",
                    class: {
                      "co-intro__greatings--replay":
                        _vm.status == "gameover" ||
                        _vm.status == "waitingReplay"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "co-intro__greatings-image",
                      attrs: { src: "assets/imgs/intro/scritta-5.png" }
                    })
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: { name: "text-fade" },
              on: {
                appear: _vm.beforeEnterLettering,
                leave: _vm.leaveLettering
              }
            },
            [
              _vm.isActive
                ? _c(
                    "div",
                    { staticClass: "co-intro__text cm-lettering js-message" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm._f("translate")("start_welcome")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { appear: "" },
          on: { appear: _vm.beforeEnterPlay, leave: _vm.leavePlay }
        },
        [
          _vm.isActive
            ? _c("div", { staticClass: "co-intro__button" }, [
                _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.handleStart($event)
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "co-intro__button-letter co-intro__button-letter--up"
                      },
                      [_vm._v("\n          P\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "co-intro__button-letter co-intro__button-letter--down"
                      },
                      [_vm._v("\n          L\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "co-intro__button-letter co-intro__button-letter--up"
                      },
                      [_vm._v("\n          A\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "co-intro__button-letter co-intro__button-letter--down"
                      },
                      [_vm._v("\n          Y\n        ")]
                    )
                  ]
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { appear: "", name: "time-fade" } },
        [_c("time-component")],
        1
      ),
      _vm._v(" "),
      _c("guide-component"),
      _vm._v(" "),
      _c("waiting-component"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "co-intro__christmas-balls-container js-christmas-balls"
        },
        [
          _c(
            "div",
            { staticClass: "co-intro__christmas-balls-container-left" },
            [
              _c("transition", { attrs: { appear: "", name: "fade" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "co-intro__christmas-ball co-intro__christmas-ball--1 js-christmas-ball"
                  },
                  [
                    _c("img", {
                      staticClass: "co-intro__christmas-ball-image",
                      attrs: {
                        src:
                          "assets/imgs/intro/christmas-balls/christmas-ball_01.png"
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { appear: "", name: "fade" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "co-intro__christmas-ball co-intro__christmas-ball--2 js-christmas-ball"
                  },
                  [
                    _c("img", {
                      staticClass: "co-intro__christmas-ball-image",
                      attrs: {
                        src:
                          "assets/imgs/intro/christmas-balls/christmas-ball_02.png"
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { appear: "", name: "fade" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "co-intro__christmas-ball co-intro__christmas-ball--3 js-christmas-ball"
                  },
                  [
                    _c("img", {
                      staticClass: "co-intro__christmas-ball-image",
                      attrs: {
                        src:
                          "assets/imgs/intro/christmas-balls/christmas-ball_03.png"
                      }
                    })
                  ]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "co-intro__christmas-balls-container-right" },
            [
              _c("transition", { attrs: { appear: "", name: "fade" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "co-intro__christmas-ball co-intro__christmas-ball--4 js-christmas-ball"
                  },
                  [
                    _c("img", {
                      staticClass: "co-intro__christmas-ball-image",
                      attrs: {
                        src:
                          "assets/imgs/intro/christmas-balls/christmas-ball_04.png"
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { appear: "", name: "fade" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "co-intro__christmas-ball co-intro__christmas-ball--5 js-christmas-ball"
                  },
                  [
                    _c("img", {
                      staticClass: "co-intro__christmas-ball-image",
                      attrs: {
                        src:
                          "assets/imgs/intro/christmas-balls/christmas-ball_05.png"
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { appear: "", name: "fade" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "co-intro__christmas-ball co-intro__christmas-ball--6 js-christmas-ball"
                  },
                  [
                    _c("img", {
                      staticClass: "co-intro__christmas-ball-image",
                      attrs: {
                        src:
                          "assets/imgs/intro/christmas-balls/christmas-ball_06.png"
                      }
                    })
                  ]
                )
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "co-intro__rotate-device" }, [
      _c("img", {
        staticClass: "co-intro__rotate-device-image",
        attrs: { src: "assets/imgs/rotate.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }