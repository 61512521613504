var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isActive
    ? _c(
        "div",
        {
          staticClass: "cm-time-container",
          class: { "cm-time-container--is-playing": _vm.isActive }
        },
        [
          _c("div", { staticClass: "cm-time-container__info" }, [
            _c("div", { staticClass: "cm-time-container__item" }, [
              _c("div", { staticClass: "cm-time-container__item-label" }, [
                _vm._v("level")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cm-time-container__item-value" }, [
                _vm._v(_vm._s(_vm.level))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cm-time-container__item" }, [
              _c("div", { staticClass: "cm-time-container__item-label" }, [
                _vm._v("energy")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cm-time-container__item-value" }, [
                _c("div", { staticClass: "cm-energy" }, [
                  _c("div", { staticClass: "cm-energy__bg" }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "cm-energy__fuel",
                    style: { width: _vm.energy + "%" }
                  })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cm-time-container__item" }, [
              _c("div", { staticClass: "cm-time-container__item-label" }, [
                _vm._v("distance")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cm-time-container__item-value" }, [
                _vm._v(_vm._s(_vm.gameDistance))
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }