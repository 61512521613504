import { Howl } from 'howler';
import { Colors, game } from './Settings';
import { Dispatcher } from '../../components/ganimede/dist/ganimede.min';

const THREE = require('three');

const { enemiesPool } = game;

export const Enemy = function () {
  const geom = new THREE.TetrahedronGeometry(8, 2);
  const mat = new THREE.MeshPhongMaterial({
    color: 0x473f37,
    shininess: 0,
    specular: 0xffffff,
    flatShading: THREE.FlatShading,
  });
  this.mesh = new THREE.Mesh(geom, mat);
  this.mesh.castShadow = true;
  this.angle = 0;
  this.dist = 0;
};

export class EnemiesHolder extends Dispatcher {
  constructor() {
    super(['boom']);
    this.mesh = new THREE.Object3D();
    this.EnemiesInUse = [];
    this.sound = new Howl({
      src: ['./assets/sound-fx/punch.mp3'],
    });
  }

  spawnEnemies() {
    const nEnemies = game.level;

    for (let i = 0; i < nEnemies; i += 1) {
      let enemy;
      if (enemiesPool.length) {
        enemy = enemiesPool.pop();
      } else {
        enemy = new Enemy();
      }

      enemy.angle = -(i * 0.1);
      enemy.distance = game.seaRadius + game.planeDefaultHeight + (-1 + Math.random() * 2) * (game.planeAmpHeight - 20);
      enemy.mesh.position.y = -game.seaRadius + Math.sin(enemy.angle) * enemy.distance;
      enemy.mesh.position.x = Math.cos(enemy.angle) * enemy.distance;
      this.mesh.add(enemy.mesh);
      this.EnemiesInUse.push(enemy);
    }
  }

  rotateEnemies(deltaTime, airplane, status) {
    for (let i = 0; i < this.EnemiesInUse.length; i += 1) {
      const enemy = this.EnemiesInUse[i];
      enemy.angle += game.speed * deltaTime * game.enemiesSpeed;

      if (enemy.angle > Math.PI * 2) enemy.angle -= Math.PI * 2;

      enemy.mesh.position.y = -game.seaRadius + Math.sin(enemy.angle) * enemy.distance;
      enemy.mesh.position.x = Math.cos(enemy.angle) * enemy.distance;
      enemy.mesh.position.z = -250;

      enemy.mesh.rotation.z += Math.random() * 0.1;
      enemy.mesh.rotation.y += Math.random() * 0.1;

      // var globalenemyPosition =  enemy.mesh.localToWorld(new THREE.Vector3());
      const diffPos = airplane.mesh.position.clone().sub(enemy.mesh.position.clone());
      const d = diffPos.length();

      if (d < game.enemyDistanceTolerance && status === 'playing') {
        // particlesHolder.spawnParticles(enemy.mesh.position.clone(), 15, Colors.red, 3);
        //
        enemiesPool.unshift(this.EnemiesInUse.splice(i, 1)[0]);
        this.mesh.remove(enemy.mesh);
        game.planeCollisionSpeedX = 100 * diffPos.x / d;
        game.planeCollisionSpeedY = 100 * diffPos.y / d;
        // ambientLight.intensity = 2;
        //
        // removeEnergy();
        i -= 1;
        this.emit('boom', enemy);
      } else if (enemy.angle > Math.PI) {
        enemiesPool.unshift(this.EnemiesInUse.splice(i, 1)[0]);
        this.mesh.remove(enemy.mesh);
        i -= 1;
      }
    }
  }

  playSound() {
    this.sound.play();
  }

  destroyAll() {
    for (let i = 0; i < this.EnemiesInUse.length; i += 1) {
      const enemy = this.EnemiesInUse[i];
      enemiesPool.unshift(this.EnemiesInUse.splice(i, 1)[0]);
      this.mesh.remove(enemy.mesh);
    }
    this.mesh.children.forEach((item) => {
      this.mesh.remove(item);
    });
  }
}
