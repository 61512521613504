const THREE = require('three');

class Snow {
  constructor() {
    this.mesh = new THREE.Object3D();
    const geometry = new THREE.BufferGeometry();
    const vertices = [];
    this.materials = [];

    const textureLoader = new THREE.TextureLoader();
    const sprite1 = textureLoader.load('assets/imgs/snowflake1.png');
    const sprite2 = textureLoader.load('assets/imgs/snowflake2.png');
    const sprite3 = textureLoader.load('assets/imgs/snowflake3.png');
    const sprite4 = textureLoader.load('assets/imgs/snowflake4.png');
    const sprite5 = textureLoader.load('assets/imgs/snowflake5.png');

    // for (let i = 0; i < 500; i += 1) {
    for (let i = 0; i < 400; i += 1) {
      const x = Math.random() * 2000 - 1000;
      const y = Math.random() * 2000 - 1000;
      const z = Math.random() * 2000 - 1000;
      vertices.push(x, y, z);
    }
    geometry.addAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    this.parameters = [
      [[1.0, 0.2, 0.5], sprite2, 20],
      [[0.95, 0.1, 0.5], sprite3, 15],
      [[0.90, 0.05, 0.5], sprite1, 10],
      [[0.85, 0, 0.5], sprite5, 8],
      [[0.80, 0, 0.5], sprite4, 5],
    ];
    for (let i = 0; i < this.parameters.length; i += 1) {
      const color = this.parameters[i][0];
      const sprite = this.parameters[i][1];
      const size = this.parameters[i][2];
      this.materials[i] = new THREE.PointsMaterial({
        size,
        map: sprite,
        blending: THREE.AdditiveBlending,
        depthTest: false,
        transparent: true,
      });
      this.materials[i].color.setHSL(color[0], color[1], color[2]);
      const particles = new THREE.Points(geometry, this.materials[i]);
      particles.rotation.x = Math.random() * 6;
      particles.rotation.y = Math.random() * 6;
      particles.rotation.z = Math.random() * 6;
      this.mesh.add(particles);
    }
  }

  update(deltaTime) {
    for (let i = 0; i < this.mesh.children.length; i += 1) {
      const object = this.mesh.children[i];
      if (object instanceof THREE.Points) {
        object.rotation.y = deltaTime * (i < 4 ? i + 1 : -(i + 1));
      }
    }
    for (let i = 0; i < this.materials.length; i += 1) {
      const color = this.parameters[i][0];
      const h = ((360 * (color[0] + deltaTime)) % 360) / 360;
      this.materials[i].color.setHSL(h, color[1], color[2]);
    }
  }
}

export default Snow;
