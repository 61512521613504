<template>
  <transition
    name="fade"
    v-on:before-enter="beforeEnter"
    v-on:after-enter="afterEnter"
    v-on:leave="leave"
    v-on:after-leave="afterLeave"
  >
  <div class="cm-level" v-if="isActive">
    <div class="cm-level__picture">
      <div class="cm-postcard">
        <a :href="link" target="_blank">
          <div class="cm-postcard__media">
            <img :src="picture" alt="" class="cm-postcard__picture">
            <canvas id="snow-canvas"></canvas>
          </div>
        <p class="cm-postcard__link">scopri tutti i viaggi {{label}}</p>
        </a>
      </div>
    </div>
    <div class="cm-level__text">
      <div class="cm-lettering cm-level__message cm-level__message-level js-message">
        {{ $t('level_city', {'destination': label}) }}
      </div>
      <!-- <div class="cm-lettering cm-level__message cm-level__message-level js-message" v-else>
        {{ $t('level_km_simple', {'distance': distance}) }}
      </div> -->
      <div class="cm-lettering cm-level__message cm-level__message-city js-message">
        {{ $t('level_km', {'distance': distance, 'level': level}) }}
      </div>
      <!-- <div class="cm-lettering cm-level__message cm-level__message-city js-message" v-else>
        {{ $t('level_circonferenza', {'circonferenza': circonferenza}) }}
      </div> -->
      <div class="cm-button cm-level__button" @click.stop="handleStart">
        vai al prossimo livello
      </div>
    </div>
  </div>
  </transition>
</template>

<script>

import getCity from '../helpers/Cities';
import $ from 'jquery';
import { TweenMax } from 'gsap';
require('letteringjs');

import Snow from '../snow/snow1';

export default {
  name: 'LevelComponent',
  computed: {
    level () {
      return this.$store.getters.level;
    },
    oldLevel () {
      return this.$store.getters.level - 1;
    },
    distance () {
      return this.$store.getters.distance * 4;
    },
    isActive () {
      return this.$store.getters.status === 'level' && this.$store.getters.level !== 1;
      // return this.$store.getters.status === 'level' && this.$store.getters.level == 1;
    },
    destination () {
      return this.$store.getters.destination;
    },
    picture() {
      return App.IMGS_URL + '/cards/' + this.destination.picture;
    },
    label() {
      return this.destination.label;
    },
    link() {
      return 'https://www.earthviaggi.it/' + this.$store.getters.destination.id;
    },
    // city () {
    //   return getCity(this.oldLevel).label;
    // },
    circonferenza () {
      return 40000 - this.distance;
    }
  },
  mounted() {
    if (this.$store.getters.status === 'level') {
      this.$store.dispatch('setDestination');
      const image = new Image();
      image.onload = () => {
        Snow.play();
      }
      image.src = this.picture;
    }
  },
  methods: {
    beforeEnter(el) {
      $(el).find('.js-message').lettering('words');
      $(el).find('.js-message span').lettering();
      TweenMax.staggerTo($(el).find('span[class^="char"]').toArray(), 0.3, {
        className: '+=open'
      }, 0.02, () => {
        // setTimeout(() => {
        //   this.$store.commit('setCameraMode', 'thirdPerson');
        //   this.$store.commit('setStatus', 'playing');
        // }, 4000);
      });
      TweenMax.from($(el).find('.cm-postcard'), 0.5, {
        delay: 0.3,
        top: 100,
        opacity: 0,
        clearProps: 'all',
      });
    },
    leave(el, done) {
      TweenMax.to($(el).find('.cm-postcard'), 0.3, {
        className: '+=is-closed'
      });
      TweenMax.staggerTo($(el).find('span[class^="char"]').toArray(), 0.2, {
        className: '+=close'
      }, 0.01, function() {
        done();
      });
    },
    afterLeave(el) {
      Snow.stop();
    },
    afterEnter(el) {
      const image = new Image();
      image.onload = () => {
        Snow.play();
      }
      image.src = this.picture;
    },
    handleStart: function (event) {
      this.$store.commit('setCameraMode', 'thirdPerson');
      this.$store.commit('setStatus', 'playing');
      return false;
    }
  },
};

</script>
