var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "fade" },
      on: { "before-enter": _vm.beforeEnter, leave: _vm.leave }
    },
    [
      _vm.isActive
        ? _c("div", { staticClass: "cm-waiting-replay" }, [
            _vm.isWinner
              ? _c("div", { staticClass: "cm-waiting-replay__message-group" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "cm-waiting-replay__message cm-waiting-replay__message--main cm-lettering js-message"
                    },
                    [_vm._v("Complementi! Hai fatto il giro del mondo!")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "cm-waiting-replay__message cm-waiting-replay__message--secondary cm-lettering js-message"
                    },
                    [
                      _vm._v(
                        "Hai percorso consegnato i pacchetti in tutti i continenti"
                      )
                    ]
                  )
                ])
              : _c("div", { staticClass: "cm-waiting-replay__message-group" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "cm-waiting-replay__message cm-waiting-replay__message--main cm-lettering js-message"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("waiting_km", { distance: _vm.distance }))
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "cm-waiting-replay__message cm-waiting-replay__message--secondary cm-lettering js-message"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("level_circonferenza", {
                            circonferenza: _vm.circonferenza
                          })
                        )
                      )
                    ]
                  )
                ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "cm-waiting-replay__btn cm-lettering a-button js-message js-hover-color",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.handleStart($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm._f("translate")("waiting_replay")) +
                    "\n  "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "cm-waiting-replay__line" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "cm-waiting-replay__message cm-waiting-replay__message--social-title cm-lettering js-message"
              },
              [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm._f("translate")("waiting_share")) +
                    "\n  "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "cm-waiting-replay__social-box" }, [
              _c(
                "div",
                { staticClass: "cm-waiting-replay__social-item js-social" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://www.facebook.com/sharer/sharer.php?u=https%3A//" +
                          _vm.socialLink,
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "cm-waiting-replay__social-item-img",
                        attrs: { src: "assets/imgs/social/facebook.png" }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cm-waiting-replay__social-item js-social" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://twitter.com/home?status=https%3A//" +
                          _vm.socialLink,
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "cm-waiting-replay__social-item-img",
                        attrs: { src: "assets/imgs/social/twitter.png" }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cm-waiting-replay__social-item js-social" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "whatsapp://send?text=https://" + _vm.socialLink,
                        "data-action": "share/whatsapp/share"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "cm-waiting-replay__social-item-img",
                        attrs: { src: "assets/imgs/social/whatsapp.png" }
                      })
                    ]
                  )
                ]
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }