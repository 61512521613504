import { Colors } from './Settings';

const THREE = require('three');

const petalColors = [Colors.red, Colors.yellow, Colors.blue];

const Flower = function () {
  this.mesh = new THREE.Object3D();

  const geomStem = new THREE.BoxGeometry(5, 50, 5, 1, 1, 1);
  const matStem = new THREE.MeshPhongMaterial({ color: Colors.green, flatShading: THREE.FlatShading });
  const stem = new THREE.Mesh(geomStem, matStem);
  stem.castShadow = false;
  stem.receiveShadow = true;
  this.mesh.add(stem);


  const geomPetalCore = new THREE.BoxGeometry(10, 10, 10, 1, 1, 1);
  const matPetalCore = new THREE.MeshPhongMaterial({ color: Colors.yellow, flatShading: THREE.FlatShading });
  const petalCore = new THREE.Mesh(geomPetalCore, matPetalCore);
  petalCore.castShadow = false;
  petalCore.receiveShadow = true;

  const petalColor = petalColors[Math.floor(Math.random() * 3)];

  const geomPetal = new THREE.BoxGeometry(15, 20, 5, 1, 1, 1);
  const matPetal = new THREE.MeshBasicMaterial({ color: petalColor });
  geomPetal.vertices[5].y -= 4;
  geomPetal.vertices[4].y -= 4;
  geomPetal.vertices[7].y += 4;
  geomPetal.vertices[6].y += 4;
  geomPetal.translate(12.5, 0, 3);

  const petals = [];
  for (let i = 0; i < 4; i++) {
    petals[i] = new THREE.Mesh(geomPetal, matPetal);
    petals[i].rotation.z = i * Math.PI / 2;
    petals[i].castShadow = true;
    petals[i].receiveShadow = true;
  }

  petalCore.add(petals[0], petals[1], petals[2], petals[3]);
  petalCore.position.y = 25;
  petalCore.position.z = 3;
  this.mesh.add(petalCore);
};

export default Flower;
