<template>
  <div>
    <div id="world"></div>
    <transition
      v-on:before-enter="beforeEnter"
      v-on:leave="leave"
    >
      <div class="cm-game-instruction" v-if="isGuide">
        <div class="cm-game-instruction__text cm-lettering js-message js-lettering" v-if="isMobile">{{'guide_tilt_phone' | translate}}</div>
        <div class="cm-game-instruction__text cm-lettering js-message js-lettering" v-else>{{'guide_move_mouse' | translate}}</div>
      </div>
    </transition>
  </div>
</template>

<script>

import $ from 'jquery';
import Game from '../three/game';

export default {
  name: 'GameComponent',
  mounted() {
    Game.init(this.$store);
  },
  data: function () {
    return {
      count: 0
    }
  },
  computed: {
    isGuide () {
      if (this.$store.getters.level === 1 && this.$store.getters.status === 'playing' && this.count === 0) {
        setTimeout(() => {
          $(this.$el).find('.cm-game-instruction').addClass('open');
        }, 1000);
        setTimeout(() => {
          this.count++;
        }, 5000);
      }
      return this.$store.getters.level === 1 && this.$store.getters.status === 'playing' && this.count === 0;
    },
    oldLevel () {
      return this.$store.getters.level - 1;
    },
    distance () {
      return this.$store.getters.distance * 4;
    },
    isActive () {
      return this.$store.getters.status == 'level' && this.$store.getters.level !== 1;
    },
    city () {
      return getCity(this.oldLevel);
    },
    circonferenza () {
      return 40000 - this.distance;
    },
    isMobile () {
      return this.$store.getters.isMobile;
    }
  },
  methods: {
    beforeEnter(el) {
      $(el).find('.js-message').lettering('words');
      $(el).find('.js-message span').lettering();
      $(el).find('span[class^="char"]').addClass('js-letter');
      TweenMax.staggerTo($(el).find('.js-letter').toArray(), 0.3, {
        className: '+=open',
        delay: 1,
      }, 0.02);
    },
    leave(el, done) {
      $(this.$el).find('.cm-game-instruction').removeClass('open');
      TweenMax.staggerTo($(el).find('.js-letter').toArray(), 0.2, {
        className: '+=close'
      }, 0.01, () => {
        this.$store.commit('setStatus', 'playing');
        done();
      });
    },
  }
};

</script>
