import { Colors, game } from './Settings';
import Packet from './Packet';

const THREE = require('three');

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


const Banner = function () {
  this.packets = [];
  this.mesh = new THREE.Object3D();
  this.count = 0;
  // const bannerGeom = new THREE.BoxGeometry(100, 5, 50, 1, 1, 1);
  // const bannerMat = new THREE.MeshPhongMaterial({ color: Colors.white, flatShading: THREE.FlatShading });
  // const banner = new THREE.Mesh(bannerGeom, bannerMat);
  // this.mesh.add(banner);
  //
  const ropeGeom = new THREE.BoxGeometry(90, 2, 2, 1, 1, 1);
  const ropeMat = new THREE.MeshPhongMaterial({ color: Colors.white, flatShading: THREE.FlatShading });
  this.rope = new THREE.Mesh(ropeGeom, ropeMat);

  this.mesh.add(this.rope);

  const w = 20;
  const cnt = new THREE.Object3D();
  for (let i = 0; i < 4; i++) {
    for (let j = 0; j < 2; j++) {
      const p = new Packet(w, w, w);
      const startY = getRandomInt(-5, 5);
      p.mesh.position.z = j * (w + 0);
      p.mesh.position.x = i * (w + 0);
      p.mesh.position.y = startY;
      p.inertia = i * 10;
      p.startY = startY;
      cnt.add(p.mesh);
      this.packets.push(p);
    }
  }
  cnt.position.y = -5;
  cnt.position.x = -30;
  cnt.position.z = -10.5;
  this.mesh.add(cnt);
};

Banner.prototype.update = function (airplane) {
  this.packets.forEach((item) => {
    const speed = item.inertia;
    // item.mesh.position.x += (airplane.mesh.position.x - 60 - item.mesh.position.x) / speed;
    // item.mesh.position.y += (airplane.mesh.position.y - item.mesh.position.y) / speed;
    // item.mesh.position.z += (airplane.mesh.position.z - item.mesh.position.z) / speed;
    item.mesh.rotation.y += (airplane.mesh.rotation.y - item.mesh.rotation.y) / speed;
    item.mesh.rotation.z += (airplane.mesh.rotation.z - item.mesh.rotation.z) / speed;
    item.mesh.rotation.x += (airplane.mesh.rotation.x - item.mesh.rotation.x) / speed;
    item.mesh.position.y += ((airplane.mesh.position.y - this.mesh.position.y + item.startY) - item.mesh.position.y) / speed;
  });

  this.count += 0.05;
  const vThis = this.mesh.position;
  const vPlane = airplane.mesh.position;
  const d = vThis.distanceTo(vPlane);

  this.rope.position.x = d + 20;

  // let quaternion = new THREE.Quaternion(); // create one and reuse it
  // quaternion.setFromUnitVectors(vThis, vPlane);
  // this.rope.applyQuaternion(quaternion);
};

export default Banner;
