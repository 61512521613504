const THREE = require('three');

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function (width, height, depth) {
  const materials = [
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-side-2.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-side-2.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-top-2.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-top-2.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-side-2.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-side-2.png'),
    }),
  ];
  const materials2 = [
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-side.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-side.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-top.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-top.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-side.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-side.png'),
    }),
  ];
  let mats = [
    materials2, materials,
  ]
  const geom = new THREE.BoxGeometry(width, height, depth);

  this.mesh = new THREE.Mesh(geom, mats[getRandomInt(0, 1)]);
  this.mesh.castShadow = true;
}
