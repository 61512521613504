var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { attrs: { id: "world" } }),
      _vm._v(" "),
      _c(
        "transition",
        { on: { "before-enter": _vm.beforeEnter, leave: _vm.leave } },
        [
          _vm.isGuide
            ? _c("div", { staticClass: "cm-game-instruction" }, [
                _vm.isMobile
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "cm-game-instruction__text cm-lettering js-message js-lettering"
                      },
                      [_vm._v(_vm._s(_vm._f("translate")("guide_tilt_phone")))]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "cm-game-instruction__text cm-lettering js-message js-lettering"
                      },
                      [_vm._v(_vm._s(_vm._f("translate")("guide_move_mouse")))]
                    )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }