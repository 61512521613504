import { Colors } from './Settings';

const THREE = require('three');

const Tree = function () {
  this.mesh = new THREE.Object3D();

  const matTreeLeaves = new THREE.MeshPhongMaterial({ color: Colors.green, flatShading: THREE.FlatShading });
  const matTreeLeavesSnow = new THREE.MeshPhongMaterial({ color: 0xFFFFFF, flatShading: THREE.FlatShading });
  const rand = Math.random();
  let materials = [];
  if (rand > 0.5) {
    materials.push(matTreeLeavesSnow);
    materials.push(matTreeLeavesSnow);
  } else {
    materials.push(matTreeLeaves);
    materials.push(matTreeLeavesSnow);
  }

  const geonTreeBase = new THREE.BoxGeometry(10, 20, 10);
  const matTreeBase = new THREE.MeshBasicMaterial({ color: Colors.brown });
  const treeBase = new THREE.Mesh(geonTreeBase, matTreeBase);
  treeBase.castShadow = true;
  treeBase.receiveShadow = true;
  this.mesh.add(treeBase);

  const geomTreeLeaves1 = new THREE.CylinderGeometry(1, 12 * 3, 12 * 3, 4);
  const treeLeaves1 = new THREE.Mesh(geomTreeLeaves1, matTreeLeaves);
  treeLeaves1.castShadow = true;
  treeLeaves1.receiveShadow = true;
  treeLeaves1.position.y = 20;
  this.mesh.add(treeLeaves1);

  const geomTreeLeaves2 = new THREE.CylinderGeometry(1, 9 * 3, 9 * 3, 4);
  const treeLeaves2 = new THREE.Mesh(geomTreeLeaves2, materials[0]);
  treeLeaves2.castShadow = true;
  treeLeaves2.position.y = 40;
  treeLeaves2.receiveShadow = true;
  this.mesh.add(treeLeaves2);

  const geomTreeLeaves3 = new THREE.CylinderGeometry(1, 6 * 3, 6 * 3, 4);
  const treeLeaves3 = new THREE.Mesh(geomTreeLeaves3, materials[1]);
  treeLeaves3.castShadow = true;
  treeLeaves3.position.y = 55;
  treeLeaves3.receiveShadow = true;
  this.mesh.add(treeLeaves3);
};

export default Tree;
