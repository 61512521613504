/* global App */
import '@babel/polyfill';
import $ from 'jquery';
import Vue from 'vue';
import vuexI18n from 'vuex-i18n';

import store from './app/stores/store';

import GameComponent from './app/containers/GameComponent.vue';
import StartComponent from './app/containers/StartComponent.vue';
import SoundComponent from './app/containers/SoundComponent.vue';
import LevelComponent from './app/containers/LevelComponent.vue';
import CreditsComponent from './app/containers/CreditsComponent.vue';
import LinkSiteComponent from './app/containers/LinkSiteComponent.vue';

// require('/cookie-policy-banner/jquery.cookiepolicybanner');
// require('malihu-custom-scrollbar-plugin');
// require('jquery-mousewheel');
// require('jquery.cookie');

$(document).ready(() => {
  /* / --- COOKIES --- / */
  // $('body').cookiePolicyBanner({
  //   bannerURL: `${App.THEME_URL}/assets/cookie-policy-banner/template_${App.LANG}.php`,
  //   policyURL: `${App.THEME_URL}/assets/cookie-policy-banner/modello_modale_${App.LANG}.html`,
  //   // policyURL  : App.BASE_URL_LANG + '/cookie-policy',
  //   isModal: true,
  //   auto: true,
  //   event: 'click',
  //   onEstesaOpen($modal) {
  //     $('.cookie-policy-page-container').mCustomScrollbar({
  //       alwaysShowScrollbar: 1,
  //     });
  //     $('.cookie-policy-banner').addClass('open-popup');
  //     TweenMax.to($modal, 0.5, {
  //       opacity: 1,
  //     });
  //   },
  //   onEstesaClose($modal) {
  //     $('.cookie-policy-banner').removeClass('open-popup');
  //     TweenMax.to($modal, 0.5, {
  //       autoAlpha: 0,
  //       onComplete() {
  //         $modal.remove();
  //       },
  //     });
  //   },
  // });
  // let view = null;
  // Game.init();

  // Vue.use(VueSocketio, io('http://192.168.1.134:8080'), { store });

  // initialize the internationalization plugin on the vue instance. note that
  // the store must be passed to the plugin. the plugin will then generate some
  // helper functions for components (i.e. this.$i18n.set, this.$t) and on the vue
  // instance (i.e. Vue.i18n.set).
  Vue.use(vuexI18n.plugin, store);

  // please note that you must specify the name of the vuex module if it is
  // different from i18n. i.e. Vue.use(vuexI18n.plugin, store, {moduleName: 'myName'})


  // add some translations (could also be loaded from a separate file)
  // note that it is possible to use placeholders. translations can also be
  // structured as object trees and will automatically be flattened by the the
  // plugin
  const translationsEn = {
    'start_welcome': '...and a 2022 full of dazzling experiences!',
    'guide_title': 'Come around the world with us!',
    'guide_description': 'Help Santa deliver packages, dodge coal pieces and collect gifts to recharge the engine.',
    'guide_tilt_phone': 'Tilt your device to move the airplane.',
    'guide_move_mouse': 'Move your mouse to move the airplane.',
    'guide_start': 'Let\'s start!',
    'level_city': 'You delivered the packages to {destination}',
    'level_km': 'you accomplished level {level} and you travelled {distance} km',
    'level_km_simple': 'Nice work! You travelled {distance} km',
    'level_circonferenza': 'You only miss {circonferenza} Km to complete the tour of the world and deliver all the packages!',
    'waiting_km': 'You travelled {distance} Km',
    'waiting_replay': 'Replay',
    'waiting_share': 'SHARE YOUR EXPERIENCE',
  };

  // translations can be kept in separate files for each language
  // i.e. resources/i18n/de.json.
  const translationsIt = {
    'start_welcome': '...e un 2022 ricco di serenità!',
    'guide_title': 'Fai il giro del mondo con noi!',
    'guide_description': 'aiuta Babbo Natale a consegnare i pacchetti, schiva i pezzi di carbone e raccogli i regali per ricaricare il motore.',
    'guide_tilt_phone': 'Tocca lo schermo e sposta il dito per muovere l\'aereo.',
    'guide_move_mouse': 'Sposta il mouse per muovere l\'aeroplano.',
    'guide_start': 'CLICCA QUI PER GIOCARE',
    'level_city': 'Hai consegnato i pacchetti {destination}',
    'level_km': 'hai completato il livello {level}',
    'level_km_simple': 'Complimenti, hai percorso {distance} km!',
    'level_circonferenza': 'Ti mancano solo {circonferenza} Km per completare il giro del mondo e consegnare tutti i pacchetti!',
    'waiting_km': 'Hai percorso {distance} Km',
    'waiting_replay': 'Ricomincia',
    'waiting_share': 'CONDIVIDI LA TUA ESPERIENZA',
  };

  // add translations directly to the application
  Vue.i18n.add('en', translationsEn);
  Vue.i18n.add('it', translationsIt);

  // set the start locale to use
  Vue.i18n.set(App.LANG);

  const vue = new Vue({
    el: '#app',
    store,
    components: {
      GameComponent,
      StartComponent,
      SoundComponent,
      LevelComponent,
      CreditsComponent,
      LinkSiteComponent,
    },
    created() {
      this.onKeyDown = this.onKeyDown.bind(this);
      this.onFocus = this.onFocus.bind(this);
      this.onBlur = this.onBlur.bind(this);
      document.addEventListener('keydown', this.onKeyDown);
      window.addEventListener('focus', this.onFocus);
      window.addEventListener('blur', this.onBlur);
    },
    methods: {
      onKeyDown(e) {
        switch (e.key) {
          case 'a':
            this.$store.commit('setCameraMode', 'thirdPerson');
            break; // 'a' key
          case 's':
            this.$store.commit('setCameraMode', 'firstPerson');
            break; // 'b' key
          case 'd':
            this.$store.commit('setCameraMode', 'thirdPersonFront');
            break; // 'b' key
          default:
        }
      },
      onFocus(e) {
        // console.log(e);
      },
      onBlur(e) {
        // console.log(e);
        // this.$store.commit('setStatus', 'pause');
      },
    },
  });
});
