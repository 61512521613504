<template>
  <div class="cm-sound js-sound" @click.stop="handleStart" v-bind:class="{
    'cm-sound--is-stopped': !sound,
  }">
    <img src="assets/imgs/icona-sound.png" class="cm-sound__ico" />
  </div>
</template>

<script>

import { Howl } from 'howler';

export default {
  name: 'SoundComponent',
  data: function () {
    return {
      soundObj: null,
    }
  },
  mounted() {
    this.soundObj = new Howl({
      src: ['./assets/sound-fx/background.mp3'],
      autoplay: this.sound,
      loop: true,
    });
  },
  methods: {
    handleStart() {
      this.$store.commit('setSound', !this.sound);
    }
  },
  computed: {
    sound () {
      return this.$store.state.sound;
    }
  },
  watch: {
    sound(newValue, oldValue) {
      if (newValue && this.soundObj) {
        this.soundObj.play();
      } else {
        this.soundObj.pause();
      }
    }
  },
};

</script>
