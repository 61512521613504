<template>
  <div class="cm-credits">
    <a href="http://www.verganiegasco.it" target="_blank">Vergani&amp;Gasco</a>
  </div>
</template>

<script>

export default {
  name: 'CreditsComponent',
};


</script>
