<template>
  <div class="cm-link-site">
    <a href="https://www.earthviaggi.it/" target="_blank">Earth Viaggi</a>
  </div>
</template>

<script>

import { Howl } from 'howler';

export default {
  name: 'LinkSiteComponent',
};

</script>
