<template>
  <div class="cm-time-container" v-if="isActive" v-bind:class="{'cm-time-container--is-playing': isActive}">
    <div class="cm-time-container__info">
      <div class="cm-time-container__item">
        <div class="cm-time-container__item-label">level</div>
        <div class="cm-time-container__item-value">{{level}}</div>
      </div>
      <div class="cm-time-container__item">
        <div class="cm-time-container__item-label">energy</div>
        <div class="cm-time-container__item-value">
          <div class="cm-energy">
            <div class="cm-energy__bg"></div>
            <div class="cm-energy__fuel" v-bind:style="{ width: energy + '%' }"></div>
          </div>
        </div>
      </div>
      <div class="cm-time-container__item">
        <div class="cm-time-container__item-label">distance</div>
        <div class="cm-time-container__item-value">{{ gameDistance }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TimeComponent',
  computed: {
    energy () {
      return this.$store.getters.energy;
    },
    gameDistance () {
      return this.$store.getters.distance * 4;
    },
    distance () {
      return this.$store.getters.distance;
    },
    time () {
      return this.$store.getters.time;
    },
    level () {
      return this.$store.getters.level;
    },
    isActive () {
      return this.$store.getters.status == 'playing';
    },
  }
};

</script>
