var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "fade" },
      on: { "before-enter": _vm.beforeEnter, leave: _vm.leave }
    },
    [
      _vm.isActive
        ? _c("div", { staticClass: "cm-guide" }, [
            _c("div", { staticClass: "cm-guide__message-group" }, [
              _c(
                "div",
                {
                  staticClass:
                    "cm-guide__message cm-guide__message--main cm-lettering js-message"
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm._f("translate")("guide_title")) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "cm-guide__message cm-guide__message--secondary u-mb-s cm-lettering js-message"
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm._f("translate")("guide_description")) +
                      "\n    "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "cm-guide__btn a-button js-message js-hover-color",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.handleStart($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n    " + _vm._s(_vm._f("translate")("guide_start")) + "\n  "
                )
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }