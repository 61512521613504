/* global App */

const citiesIt = [
  {
    id: 'suggestioni-viaggio/consigli-di-viaggio-rajasthan',
    picture: 'rajasthan.jpg',
    label: 'in Rajasthan',
  },
  {
    id: 'suggestioni-viaggio/consigli-di-viaggio-peru',
    picture: 'peru.jpg',
    label: 'in Perù',
  },
  {
    id: 'suggestioni-viaggio/consigli-di-viaggio-patagonia-argentina-e-cilena',
    picture: 'patagonia.jpg',
    label: 'in Patagonia',
  },
  {
    id: 'suggestioni-viaggio/consigli-di-viaggio-nepal',
    picture: 'nepal.jpg',
    label: 'in Nepal',
  },
  {
    id: 'suggestioni-viaggio/consigli-di-viaggio-namibia',
    picture: 'namibia.jpg',
    label: 'in Namibia',
  },
  {
    id: 'suggestioni-viaggio/consigli-di-viaggio-groenlandia',
    picture: 'groenlandia.jpg',
    label: 'sull\'Groenlandia',
  },
  {
    id: 'suggestioni-viaggio/consigli-di-viaggio-giordania',
    picture: 'giordania.jpg',
    label: 'in Giordania',
  },
  {
    id: 'suggestioni-viaggio/consigli-di-viaggio-negli-emirati-arabi-uniti',
    picture: 'emirati-arabi.jpg',
    label: 'in Emirati Arabi',
  },
  {
    id: 'suggestioni-viaggio/consigli-di-viaggio-cile',
    picture: 'cile.jpg',
    label: 'in Cile',
  },
  {
    id: 'suggestioni-viaggio/consigli-di-viaggio-baja-california',
    picture: 'baja-california.jpg',
    label: 'in Baja California',
  },
  {
    id: 'suggestioni-viaggio/consigli-di-viaggio-argentina',
    picture: 'argentina.jpg',
    label: 'in Argentina',
  },
  {
    id: 'suggestioni-viaggio/crociere-antartide',
    picture: 'antartide.jpg',
    label: 'in Antartide',
  },
];
const citiesEn = [
  {
    id: 'africa',
    picture: 'africa.jpg',
    label: 'Africa',
  },
  {
    id: 'antartide',
    picture: 'antartide.jpg',
    label: 'Antartide',
  },
  {
    id: 'asia',
    picture: 'asia.jpg',
    label: 'Asia',
  },
  {
    id: 'centro-america',
    picture: 'centro-america.jpg',
    label: 'Centro America',
  },
  {
    id: 'europa',
    picture: 'europa.jpg',
    label: 'Europa',
  },
  {
    id: 'himalaya',
    picture: 'himalaya.jpg',
    label: 'Himalaya',
  },
  {
    id: 'india-del-nord',
    picture: 'india-nord.jpg',
    label: 'India del Nord',
  },
  {
    id: 'india-del-sud',
    picture: 'india-sud.jpg',
    label: 'India del Sud',
  },
  {
    id: 'medio-oriente',
    picture: 'medio-oriente.jpg',
    label: 'Medio Oriente',
  },
  {
    id: 'nord-america',
    picture: 'nord-america.jpg',
    label: 'Nord America',
  },
  {
    id: 'peru-e-sud-america',
    picture: 'peru-sud-america.jpg',
    label: 'Perù e Sud America',
  },
  {
    id: 'sud-est-asiatico',
    picture: 'sud-est-asiatico.jpg',
    label: 'Sud Est Asiatico',
  },
];
export function getAllCities() {
  let cities = citiesIt;
  switch (App.LANG) {
    case 'it':
      cities = citiesIt;
      break;
    case 'en':
      cities = citiesEn;
      break;
    default:
      cities = citiesIt;
  }
  return cities;
}
export function getCity(level) {
  let result = null;
  let cities = citiesIt;
  switch (App.LANG) {
    case 'it':
      cities = citiesIt;
      break;
    case 'en':
      cities = citiesEn;
      break;
    default:
      cities = citiesIt;
  }
  if (level <= cities.length) {
    const list = cities;
    result = list[Math.floor(Math.random() * list.length)];
  }
  return result;
}
