import { Colors } from './Settings';
import Flower from './Flower';
import Tree from './Tree';

const THREE = require('three');

const Forest = function () {
  this.mesh = new THREE.Object3D();

  // Number of Trees
  this.nTrees = 150;

  // Number of Trees
  this.nFlowers = 0;

  // Space the consistenly
  var stepAngle = Math.PI * 2 / this.nTrees;

  // Create the Trees

  for (let i = 0; i < this.nTrees; i++) {
    const t = new Tree();

    // set rotation and position using trigonometry
    const a = stepAngle * i;
    // this is the distance between the center of the axis and the tree itself
    const h = 605;
    t.mesh.position.y = Math.sin(a) * h;
    t.mesh.position.x = Math.cos(a) * h;

    // rotate the tree according to its position
    t.mesh.rotation.z = a + (Math.PI / 2) * 3;

    // Andreas Trigo funtime
    // t.mesh.rotation.z = Math.atan2(t.mesh.position.y, t.mesh.position.x)-Math.PI/2;

    // random depth for the tree on the z-axis
    t.mesh.position.z = 0 - Math.random() * 600;

    // random scale for each tree
    var s = 0.3 + Math.random() * 0.75;
    t.mesh.scale.set(s, s, s);

    this.mesh.add(t.mesh);
  }

  var stepAngle = Math.PI * 2 / this.nFlowers;


  for (var i = 0; i < this.nFlowers; i++) {
    const f = new Flower();
    var a = stepAngle * i;

    var h = 605;
    f.mesh.position.y = Math.sin(a) * h;
    f.mesh.position.x = Math.cos(a) * h;

    f.mesh.rotation.z = a + (Math.PI / 2) * 3;

    f.mesh.position.z = 0 - Math.random() * 600;

    var s = 0.1 + Math.random() * 0.3;
    f.mesh.scale.set(s, s, s);

    this.mesh.add(f.mesh);
  }
};

export default Forest;
