import { Colors } from './Settings';

const THREE = require('three');

const Sun = function () {
  this.mesh = new THREE.Object3D();

  const sunGeom = new THREE.SphereGeometry(400, 20, 10);
  const sunMat = new THREE.MeshPhongMaterial({
    color: Colors.yellow,
    flatShading: THREE.FlatShading,
  });
  const sun = new THREE.Mesh(sunGeom, sunMat);
  // sun.applyMatrix(new THREE.Matrix4().makeRotationX(-Math.PI/2));
  sun.castShadow = false;
  sun.receiveShadow = false;
  this.mesh.add(sun);
};

export default Sun;
