<template>
  <transition
    name="fade"
    v-on:before-enter="beforeEnter"
    v-on:leave="leave"
  >
  <div class="cm-waiting-replay" v-if="isActive">
    <div class="cm-waiting-replay__message-group" v-if="isWinner">
      <div class="cm-waiting-replay__message cm-waiting-replay__message--main cm-lettering js-message">Complementi! Hai fatto il giro del mondo!</div>
      <div class="cm-waiting-replay__message cm-waiting-replay__message--secondary cm-lettering js-message">Hai percorso consegnato i pacchetti in tutti i continenti</div>
    </div>
    <div class="cm-waiting-replay__message-group" v-else>
      <div class="cm-waiting-replay__message cm-waiting-replay__message--main cm-lettering js-message">{{ $t('waiting_km', {'distance': distance}) }}</div>
      <div class="cm-waiting-replay__message cm-waiting-replay__message--secondary cm-lettering js-message">{{ $t('level_circonferenza', {'circonferenza': circonferenza}) }}</div>
    </div>
    <div @click.stop="handleStart" class="cm-waiting-replay__btn cm-lettering a-button js-message js-hover-color">
      {{'waiting_replay' | translate}}
    </div>
    <div class="cm-waiting-replay__line"></div>
    <div class="cm-waiting-replay__message cm-waiting-replay__message--social-title cm-lettering js-message">
      {{'waiting_share' | translate}}
    </div>
    <div class="cm-waiting-replay__social-box">
      <div class="cm-waiting-replay__social-item js-social">
        <a v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=https%3A//' + socialLink" target="_blank">
          <img src="assets/imgs/social/facebook.png" class="cm-waiting-replay__social-item-img">
        </a>
      </div>
      <div class="cm-waiting-replay__social-item js-social">
        <a v-bind:href="'https://twitter.com/home?status=https%3A//' + socialLink" target="_blank">
          <img src="assets/imgs/social/twitter.png" class="cm-waiting-replay__social-item-img">
        </a>
      </div>
      <div class="cm-waiting-replay__social-item js-social">
        <a v-bind:href="'whatsapp://send?text=https://' + socialLink" data-action="share/whatsapp/share">
          <img src="assets/imgs/social/whatsapp.png" class="cm-waiting-replay__social-item-img">
        </a>
      </div>
    </div>
  </div>
  </transition>
</template>

<script>

export default {
  name: 'WaitingComponent',
  computed: {
    isWinner () {
      return this.oldLevel >= 10;
    },
    level () {
      return this.$store.getters.level;
    },
    oldLevel () {
      return this.$store.getters.level - 1;
    },
    isActive () {
      return this.$store.getters.status == 'waitingReplay'  || this.$store.getters.status == 'gameover';
    },
    distance () {
      return this.$store.getters.distance * 4;
    },
    socialLink () {
      return this.$store.getters.whatsappLink;
    },
    circonferenza () {
      return 40000 - this.distance;
    }
  },
  methods: {
    beforeEnter(el) {
      $(el).find('.js-message').lettering('words');
      $(el).find('.js-message span').lettering();
      $(el).find('span[class^="char"]').addClass('js-letter');
      TweenMax.staggerTo($(el).find('.js-letter').toArray(), 0.3, {
        className: '+=open'
      }, 0.02);
      TweenMax.staggerTo($(el).find('.js-social').toArray(), 0.4, {
        className: '+=open',
        delay: 1.4,
      }, 0.05);
      $(el).find('.js-hover-color').hover(function() {
        TweenMax.staggerTo($(this).find('.js-letter').toArray(), 0.3, {
          color: '#C35342'
        }, 0.05);
      }, function() {
        TweenMax.staggerTo($(this).find('.js-letter').toArray(), 0.3, {
          color: '#FFFFFF'
        }, 0.05);
      });
    },
    leave(el, done) {
      TweenMax.staggerTo($(el).find('.js-social').toArray(), 0.4, {
        className: '+=close',
      }, 0.05);
      TweenMax.staggerTo($(el).find('.js-letter').toArray(), 0.2, {
        className: '+=close'
      }, 0.01, () => {
        this.$store.commit('setStatus', 'playing');
        done();
      });
    },

    handleStart: function (event) {
      this.$store.dispatch('reset');
      this.$store.commit('setStatus', 'pippo');
      return false;
    }
  },
};

</script>
