export const Colors = {
  red: 0xf25346,
  yellow: 0xedeb27,
  white: 0xd8d0d1,
  brown: 0x59332e,
  pink: 0xF5986E,
  brownDark: 0x23190f,
  blue: 0x68c3c0,
  green: 0x458248,
  purple: 0x551A8B,
  lightgreen: 0x629265,
};

const initialState = {
  speed: 0.00000,
  initSpeed: 0.00035,
  baseSpeed: 0.00035,
  targetBaseSpeed: 0.00035,
  incrementSpeedByTime: 0.0000025,
  incrementSpeedByLevel: 0.000005,
  distanceForSpeedUpdate: 100,
  speedLastUpdate: 0,

  distance: 0,
  ratioSpeedDistance: 50,
  energy: 100,
  ratioSpeedEnergy: 3,

  level: 1,
  levelDistance: 0,
  levelLastUpdate: 0,
  distanceForLevelUpdate: 500,

  planeDefaultHeight: 100,
  planeAmpHeight: 80,
  planeAmpWidth: 75,
  planeMoveSensivity: 0.005,
  planeRotXSensivity: 0.0008,
  planeRotZSensivity: 0.0004,
  planeFallSpeed: 0.001,
  planeMinSpeed: 1.2,
  planeMaxSpeed: 1.6,
  planeSpeed: 0,
  planeCollisionDisplacementX: 0,
  planeCollisionSpeedX: 0,

  planeCollisionDisplacementY: 0,
  planeCollisionSpeedY: 0,

  seaRadius: 600,
  seaLength: 800,
  // seaRotationSpeed:0.006,
  wavesMinAmp: 5,
  wavesMaxAmp: 20,
  wavesMinSpeed: 0.001,
  wavesMaxSpeed: 0.003,

  cameraFarPos: 500,
  cameraNearPos: 150,
  cameraSensivity: 0.002,

  coinDistanceTolerance: 15,
  coinValue: 3,
  coinsSpeed: 0.5,
  coinLastSpawn: 0,
  distanceForCoinsSpawn: 100,

  enemyDistanceTolerance: 10,
  enemyValue: 10,
  enemiesSpeed: 0.6,
  enemyLastSpawn: 0,
  distanceForEnemiesSpawn: 50,

  status: 'start',

  enemiesPool: [],
  particlesPool: [],
};

export let game = { ...initialState };

export function resetGame() {
  game = { ...initialState };
}
