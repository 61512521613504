<template>
  <div class="co-intro" v-bind:class="{
    'co-intro--is-guide': status == 'guide',
    'co-intro--is-playing': status == 'playing',
  }">
    <div class="co-intro__container-top">
      <div class="co-intro__logo">
        <a v-bind:href="siteLink" target="_blank"><img v-bind:src="logo" class="co-intro__logo-image"></a>
      </div>
      <transition
        appear
        name="greatings-fade"
      >
        <div class="co-intro__greatings" v-if="isGreatingsActive" v-bind:class="{
          'co-intro__greatings--replay': status == 'gameover' || status == 'waitingReplay'
        }">
          <img src="assets/imgs/intro/scritta-5.png" class="co-intro__greatings-image">
        </div>
      </transition>
      <transition
        name="text-fade"
        v-on:appear="beforeEnterLettering"
        v-on:leave="leaveLettering"
      >
        <div class="co-intro__text cm-lettering js-message" v-if="isActive">
          {{ 'start_welcome' | translate }}
        </div>
      </transition>
    </div>
    <transition
      appear
      v-on:appear="beforeEnterPlay"
      v-on:leave="leavePlay"
    >
      <div class="co-intro__button" v-if="isActive">
        <div @click.stop="handleStart">
          <span class="co-intro__button-letter co-intro__button-letter--up">
            P
          </span>
          <span class="co-intro__button-letter co-intro__button-letter--down">
            L
          </span>
          <span class="co-intro__button-letter co-intro__button-letter--up">
            A
          </span>
          <span class="co-intro__button-letter co-intro__button-letter--down">
            Y
          </span>
          <!-- <img src="assets/imgs/intro/play.svg" class="co-intro__button-image"> -->
        </div>
      </div>
    </transition>
    <transition
      appear
      name="time-fade"
    >
      <time-component></time-component>
    </transition>
    <guide-component></guide-component>
    <waiting-component></waiting-component>
    <div class="co-intro__rotate-device">
      <img src="assets/imgs/rotate.png" class="co-intro__rotate-device-image">
    </div>
    <div class="co-intro__christmas-balls-container js-christmas-balls">
      <div class="co-intro__christmas-balls-container-left">
        <transition
          appear
          name="fade"
        >
          <div class="co-intro__christmas-ball co-intro__christmas-ball--1 js-christmas-ball">
            <img src="assets/imgs/intro/christmas-balls/christmas-ball_01.png" class="co-intro__christmas-ball-image">
          </div>
        </transition>
        <transition
          appear
          name="fade"
        >
          <div class="co-intro__christmas-ball co-intro__christmas-ball--2 js-christmas-ball">
            <img src="assets/imgs/intro/christmas-balls/christmas-ball_02.png" class="co-intro__christmas-ball-image">
          </div>
        </transition>
        <transition
          appear
          name="fade"
        >
          <div class="co-intro__christmas-ball co-intro__christmas-ball--3 js-christmas-ball">
            <img src="assets/imgs/intro/christmas-balls/christmas-ball_03.png" class="co-intro__christmas-ball-image">
          </div>
        </transition>
      </div>
      <div class="co-intro__christmas-balls-container-right">
        <transition
          appear
          name="fade"
        >
          <div class="co-intro__christmas-ball co-intro__christmas-ball--4 js-christmas-ball">
            <img src="assets/imgs/intro/christmas-balls/christmas-ball_04.png" class="co-intro__christmas-ball-image">
          </div>
        </transition>
        <transition
          appear
          name="fade"
        >
          <div class="co-intro__christmas-ball co-intro__christmas-ball--5 js-christmas-ball">
            <img src="assets/imgs/intro/christmas-balls/christmas-ball_05.png" class="co-intro__christmas-ball-image">
          </div>
        </transition>
        <transition
          appear
          name="fade"
        >
          <div class="co-intro__christmas-ball co-intro__christmas-ball--6 js-christmas-ball">
            <img src="assets/imgs/intro/christmas-balls/christmas-ball_06.png" class="co-intro__christmas-ball-image">
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

import $ from 'jquery';
import TimeComponent from './TimeComponent.vue';
import GuideComponent from './GuideComponent.vue';
import WaitingComponent from './WaitingComponent.vue';

export default {
  name: 'StartComponent',
  data: function () {
    return {
      intervalID: null,
    }
  },
  mounted() {

  },
  methods: {
    handleStart: function (event) {
      $(this.$el).find('.co-intro__button').off('mouseenter mouseleave');
      TweenMax.killTweensOf($('.co-intro__button-letter'));
      this.$store.commit('setStatus', 'guide');
      return false;
    },
    beforeEnterLettering(el) {
      $(this.$el).find('.co-intro__button').hover(
      function(el) {
        TweenMax.killTweensOf($('.co-intro__button-letter'));
        TweenMax.staggerTo($('.co-intro__button-letter'), 0.35, {
          transform: 'none',
          color: 'white',
          ease: Circ.none,
        }, 0.02);
      }, function() {
        TweenMax.killTweensOf($('.co-intro__button-letter'));
        TweenMax.staggerTo($('.co-intro__button-letter.co-intro__button-letter--up'), 0.35, {
          transform: 'translateY(-4px)',
          color: '#D24A3A',
          ease: Circ.none,
        }, 0.02);
        TweenMax.staggerTo($('.co-intro__button-letter.co-intro__button-letter--down'), 0.35, {
          transform: 'translateY(4px)',
          color: '#D24A3A',
          ease: Circ.none,
        }, 0.02);
      });
      $(el).lettering('words');
      $(el).find('span').lettering();
      TweenMax.staggerTo($(el).find('span[class^="char"]').toArray(), 0.3, {
        className: '+=open'
      }, 0.02);
    },
    leaveLettering(el, done) {
      TweenMax.staggerTo($(el).find('span[class^="char"]').toArray(), 0.2, {
        className: '+=close'
      }, 0.01, function() {
        done();
      });
    },
    beforeEnterPlay(el) {
      TweenMax.staggerFrom($(el).find('.co-intro__button-letter').toArray(), 0.3, {
        className: '+=close',
        delay: 0.5
      }, 0.03);
    },
    leavePlay(el, done) {
      TweenMax.staggerTo($(el).find('.co-intro__button-letter').toArray(), 0.4, {
        className: '+=close'
      }, 0.03, function() {
        done();
      });
    },
  },
  computed: {
    status () {
      switch (this.$store.getters.status) {
        case 'start':
          this.intervalID = setInterval(() => {
            switch (this.$store.getters.cameraMode) {
              case 'firstPerson':
                this.$store.commit('setCameraMode', 'thirdPerson');
                break;
              case 'thirdPerson':
                this.$store.commit('setCameraMode', 'thirdPersonFront');
                break;
              case 'thirdPersonFront':
                this.$store.commit('setCameraMode', 'firstPerson');
                break;
              default:

            }
          }, 6000);
          break;
        default:
        clearInterval(this.intervalID);
      }
      return this.$store.getters.status;
    },
    isActive () {
      return this.$store.getters.status == 'start';
    },
    isGreatingsActive () {
      return this.$store.getters.status == 'start' || this.$store.getters.status == 'waitingReplay'  || this.$store.getters.status == 'gameover';
    },
    orientation () {
      return this.$store.getters.orientation;
    },
    logo () {
      return 'assets/imgs/'+this.$store.getters.logo;
    },
    siteLink () {
      return this.$store.getters.siteLink;
    },
  },
  watch: {
    status(newValue, oldValue) {

    }
  },
  components: {
    TimeComponent,
    GuideComponent,
    WaitingComponent,
  }

};

</script>
