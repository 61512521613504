<template>
  <transition
    name="fade"
    v-on:before-enter="beforeEnter"
    v-on:leave="leave"
  >
  <div class="cm-guide" v-if="isActive">
    <div class="cm-guide__message-group">
      <div class="cm-guide__message cm-guide__message--main cm-lettering js-message">
        {{ 'guide_title' | translate }}
      </div>
      <div class="cm-guide__message cm-guide__message--secondary u-mb-s cm-lettering js-message">
        {{ 'guide_description' | translate }}
      </div>
    </div>
    <div @click.stop="handleStart" class="cm-guide__btn a-button js-message js-hover-color">
      {{ 'guide_start' | translate }}
    </div>
  </div>
  </transition>
</template>

<script>

import $ from 'jquery';
import { TweenMax } from 'gsap';
require('letteringjs');

export default {
  name: 'GuideComponent',
  computed: {
    isActive () {
      return this.$store.getters.status == 'guide';
    },
    isMobile () {
      return this.$store.getters.isMobile;
    }
  },
  methods: {
    beforeEnter(el) {
      $(el).find('.js-message').lettering('words');
      $(el).find('.js-message span').lettering();
      TweenMax.staggerTo($(el).find('span[class^="char"]').toArray(), 0.3, {
        className: '+=open'
      }, 0.02);

      $(el).find('.js-hover-color').hover(function() {
        TweenMax.staggerTo($(this).find('span[class^="char"]').toArray(), 0.3, {
          color: '#C35342'
        }, 0.05);
      }, function() {
        TweenMax.staggerTo($(this).find('span[class^="char"]').toArray(), 0.3, {
          color: '#FFFFFF'
        }, 0.05);
      });

    },
    leave(el, done) {
      TweenMax.staggerTo($(el).find('span[class^="char"]').toArray().reverse(), 0.2, {
        className: '+=close'
      }, 0.01, function() {
        done();
      });
    },
    handleStart: function (event) {
      this.$store.commit('setStatus', 'playing');
      return false;
    }
  }
};

</script>
