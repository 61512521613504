import $ from 'jquery';
import { TweenMax } from 'gsap';
import Snow from './Snow';
import Land from './Land';
import Orbit from './Orbit';
import Sun from './Sun';
import AirPlane from './AirPlane';
import Forest from './Forest';
import Cloud from './Cloud';
import Banner from './Banner';
import { Enemy, EnemiesHolder } from './EnemiesHolder';
import { Particle, ParticlesHolder } from './Particles';
import { CoinsHolder } from './Coins';
import normalize from './helpers/normalize';

import { Colors, game, resetGame } from './Settings';

const { enemiesPool, particlesPool } = game;

const THREE = require('three');

window.THREE = THREE;

require('three/examples/js/postprocessing/EffectComposer');
require('three/examples/js/postprocessing/RenderPass');
require('three/examples/js/shaders/FilmShader');
require('three/examples/js/postprocessing/FilmPass');
require('three/examples/js/postprocessing/ShaderPass');
require('three/examples/js/shaders/DigitalGlitch');
require('three/examples/js/shaders/CopyShader');
require('three/examples/js/shaders/VignetteShader');
require('three/examples/js/postprocessing/GlitchPass');

let scene;
let camera;
let fieldOfView;
let aspectRatio;
let nearPlane;
let farPlane;
let HEIGHT;
let WIDTH;
let renderer;
let container;
let composer;
let currentTime = 0;
let oldTime = 0;
let deltaTime = 0;
let packets = [];
let enemiesHolder;
let coinsHolder;
let particlesHolder;

let hemisphereLight;
let shadowLight;

let store = null;

function setGameStart() {
  resetGame();
  store.commit('setStatus', 'start');
}
function setGamePlay() {
  resetGame();
  store.commit('setStatus', 'playing');
  store.commit('setLevel', 1);
}
function setGameOver() {
  store.commit('setStatus', 'gameover');
}
function setGameWaiting() {
  store.commit('setStatus', 'waitingReplay');
}

// RESPONSIVE FUNCTION
function handleWindowResize() {
  HEIGHT = window.innerHeight;
  WIDTH = window.innerWidth;
  renderer.setSize(WIDTH, HEIGHT);
  composer.setSize(WIDTH, HEIGHT);
  camera.aspect = WIDTH / HEIGHT;
  camera.updateProjectionMatrix();
}
function handleOrientation(evt) {
  this.alpha = evt.alpha;
  this.beta = evt.beta;
  this.gamma = evt.gamma;
  const orientation = {
    alpha: evt.alpha,
    beta: evt.beta,
    gamma: evt.gamma,
  };
  store.commit('setOrientation', orientation);
}

function createScene() {
  HEIGHT = window.innerHeight;
  WIDTH = window.innerWidth;

  // Create the scene.
  scene = new THREE.Scene();

  // Add FOV Fog effect to the scene. Same colour as the BG int he stylesheet.
  scene.fog = new THREE.Fog(0xf7d9aa, 100, 950);

  // Create the camera
  aspectRatio = WIDTH / HEIGHT;
  fieldOfView = 60;
  nearPlane = 1;
  farPlane = 10000;
  camera = new THREE.PerspectiveCamera(
    fieldOfView,
    aspectRatio,
    nearPlane,
    farPlane,
  );
  // Position the camera
  camera.position.x = 0;
  camera.position.y = 150;
  camera.position.z = 100;


  // Create the renderer

  renderer = new THREE.WebGLRenderer({
    // Alpha makes the background transparent, antialias is performant heavy
    // alpha: true,
    // antialias: true,
  });
  renderer.setClearColor(0x181a2b, 1);

  // set the size of the renderer to fullscreen
  renderer.setSize(WIDTH, HEIGHT);
  // enable shadow rendering
  renderer.shadowMap.enabled = true;

  const renderPass = new THREE.RenderPass(scene, camera);
  const effectFilm = new THREE.FilmPass(0.35, 0.025, 648, false);
  // effectFilm.renderToScreen = true;
  const effectVignette = new THREE.ShaderPass(THREE.VignetteShader);
  effectVignette.uniforms.offset.value = 0.95;
  effectVignette.uniforms.darkness.value = 1.6;
  effectVignette.renderToScreen = true;
  //
  // const effectGlitch = new THREE.GlitchPass();
  // effectGlitch.renderToScreen = true;
  //
  composer = new THREE.EffectComposer(renderer);
  composer.addPass(renderPass);
  composer.addPass(effectFilm);
  composer.addPass(effectVignette);
  // composer.addPass(effectGlitch);

  // Add the Renderer to the DOM, in the world div.
  container = document.getElementById('world');
  container.appendChild(renderer.domElement);

  // RESPONSIVE LISTENER
  window.addEventListener('resize', handleWindowResize, false);
}

function createLights() {
  // Gradient coloured light - Sky, Ground, Intensity
  hemisphereLight = new THREE.HemisphereLight(0xaaaaaa, 0x000000, 0.9);
  // Parallel rays
  shadowLight = new THREE.DirectionalLight(0xffffff, 0.9);
  shadowLight.intensity = 0.5;


  shadowLight.position.set(0, 350, 350);
  shadowLight.castShadow = true;

  // define the visible area of the projected shadow
  shadowLight.shadow.camera.left = -650;
  shadowLight.shadow.camera.right = 650;
  shadowLight.shadow.camera.top = 650;
  shadowLight.shadow.camera.bottom = -650;
  shadowLight.shadow.camera.near = 1;
  shadowLight.shadow.camera.far = 1000;

  // Shadow map size
  shadowLight.shadow.mapSize.width = 2048;
  shadowLight.shadow.mapSize.height = 2048;

  // Add the lights to the scene
  scene.add(hemisphereLight);

  scene.add(shadowLight);
}

const Sky = function () {
  this.mesh = new THREE.Object3D();

  // Number of cloud groups
  this.nClouds = 15;

  // Space the consistenly
  const stepAngle = Math.PI * 2 / this.nClouds;

  // Create the Clouds

  for (let i = 0; i < this.nClouds; i += 1) {
    const c = new Cloud();

    // set rotation and position using trigonometry
    const a = stepAngle * i;
    // this is the distance between the center of the axis and the cloud itself
    const h = 800 + Math.random() * 200;
    c.mesh.position.y = Math.sin(a) * h;
    c.mesh.position.x = Math.cos(a) * h;

    // rotate the cloud according to its position
    c.mesh.rotation.z = a + Math.PI / 2;

    // random depth for the clouds on the z-axis
    c.mesh.position.z = -400 - Math.random() * 400;

    // random scale for each cloud
    const s = 1 + Math.random() * 2;
    c.mesh.scale.set(s, s, s);

    this.mesh.add(c.mesh);
  }
};

let sky;
let forest;
let land;
let orbit;
let airplane;
let sun;
let banner;
let snow;

let mousePos = { x: 0, y: 0 };
const offSet = -600;


function createSky() {
  sky = new Sky();
  sky.mesh.position.y = offSet;
  scene.add(sky.mesh);
}

function createLand() {
  land = new Land();
  land.mesh.position.y = offSet;
  scene.add(land.mesh);
}

function createOrbit() {
  orbit = new Orbit();
  orbit.mesh.position.y = offSet;
  orbit.mesh.rotation.z = -Math.PI / 6;
  scene.add(orbit.mesh);
}

function createForest() {
  forest = new Forest();
  forest.mesh.position.y = offSet;
  scene.add(forest.mesh);
}

function createSun() {
  sun = new Sun();
  sun.mesh.scale.set(1, 1, 0.3);
  sun.mesh.position.set(0, -30, -850);
  scene.add(sun.mesh);
}


function createPlane() {
  airplane = new AirPlane();
  airplane.mesh.scale.set(0.35, 0.35, 0.35);
  airplane.mesh.position.set(-40, 110, -250);
  // airplane.mesh.rotation.z = Math.PI/15;
  scene.add(airplane.mesh);
}
function createBanner() {
  banner = new Banner();
  banner.mesh.scale.set(0.35, 0.35, 0.35);
  banner.mesh.position.set(-40, 110, -250);
  // airplane.mesh.rotation.z = Math.PI/15;
  scene.add(banner.mesh);
}

function createSnow() {
  snow = new Snow();
  scene.add(snow.mesh);
}

function createEnemies() {
  for (let i = 0; i < 10; i += 1) {
    const ennemy = new Enemy();
    enemiesPool.push(ennemy);
  }
  enemiesHolder = new EnemiesHolder();
  // ennemiesHolder.mesh.position.y = -game.seaRadius;
  scene.add(enemiesHolder.mesh);
  enemiesHolder.spawnEnemies();
  return enemiesHolder;
}

function createParticles() {
  for (let i = 0; i < 10; i += 1) {
    const particle = new Particle();
    particlesPool.push(particle);
  }
  particlesHolder = new ParticlesHolder();
  // ennemiesHolder.mesh.position.y = -game.seaRadius;
  scene.add(particlesHolder.mesh);
}
function createCoins() {
  coinsHolder = new CoinsHolder(20);
  scene.add(coinsHolder.mesh);
}
function updatePlane() {
  let targetY = normalize(mousePos.y, -0.75, 0.75, 50, 190);
  let targetX = normalize(mousePos.x, -0.75, 0.75, -100, -20);

  game.planeCollisionDisplacementX += game.planeCollisionSpeedX;
  targetX += game.planeCollisionDisplacementX;


  game.planeCollisionDisplacementY += game.planeCollisionSpeedY;
  targetY += game.planeCollisionDisplacementY;

  // Move the plane at each frame by adding a fraction of the remaining distance
  airplane.mesh.position.y += (targetY - airplane.mesh.position.y) * 0.2;

  airplane.mesh.position.x += (targetX - airplane.mesh.position.x) * 0.2;


  // Rotate the plane proportionally to the remaining distance
  airplane.mesh.rotation.z = (targetY - airplane.mesh.position.y) * 0.0128;
  airplane.mesh.rotation.x = (airplane.mesh.position.y - targetY) * 0.0064;
  airplane.mesh.rotation.y = (airplane.mesh.position.x - targetX) * 0.0064;

  // const targetCameraZ = normalize(game.planeSpeed, game.planeMinSpeed, game.planeMaxSpeed, game.cameraNearPos, game.cameraFarPos);
  // camera.fov = normalize(mousePos.x, -1, 1, 40, 80);
  // camera.updateProjectionMatrix();
  // camera.position.y += (airplane.mesh.position.y - camera.position.y) * deltaTime * game.cameraSensivity;

  game.planeCollisionSpeedX += (0 - game.planeCollisionSpeedX) * deltaTime * 0.03;
  game.planeCollisionDisplacementX += (0 - game.planeCollisionDisplacementX) * deltaTime * 0.01;
  game.planeCollisionSpeedY += (0 - game.planeCollisionSpeedY) * deltaTime * 0.03;
  game.planeCollisionDisplacementY += (0 - game.planeCollisionDisplacementY) * deltaTime * 0.01;

  airplane.propeller.rotation.x += 0.3;
}

function updateBanner() {
  const speed = 10;
  banner.mesh.position.x += (airplane.mesh.position.x - 60 - banner.mesh.position.x) / speed;
  banner.mesh.position.y += (airplane.mesh.position.y - banner.mesh.position.y) / speed;
  banner.mesh.position.z += (airplane.mesh.position.z - banner.mesh.position.z) / speed;
  banner.mesh.rotation.y += (airplane.mesh.rotation.y - banner.mesh.rotation.y) / speed;
  banner.mesh.rotation.z += (airplane.mesh.rotation.z - banner.mesh.rotation.z) / speed;
  banner.mesh.rotation.x += (airplane.mesh.rotation.x - banner.mesh.rotation.x) / speed;
  banner.update(airplane);
}

function updateDistance() {
  // game.distance += game.speed * deltaTime * game.ratioSpeedDistance;
  game.distance = game.levelDistance + (game.distanceForLevelUpdate * (game.level - 1));
  store.commit('setDistance', Math.round(game.distance));
}
function updateEnergy() {
  game.energy -= game.speed * deltaTime * game.ratioSpeedEnergy;
  game.energy = Math.max(0, game.energy);
  store.commit('setEnergy', Math.round(game.energy));
  if (game.energy < 1) {
    setGameOver();
  }
}
function addEnergy() {
  game.energy += game.coinValue;
  game.energy = Math.min(game.energy, 100);
}

function removeEnergy() {
  game.energy -= game.enemyValue;
  game.energy = Math.max(0, game.energy);
}

function updateCameraFirstPerson() {
  camera.position.z += (airplane.mesh.position.z - camera.position.z) / 20;
  camera.position.x += (airplane.mesh.position.x - 120 - camera.position.x) / 20;
  camera.position.y += (airplane.mesh.position.y + 120 - camera.position.y) / 20;
  camera.lookAt(airplane.mesh.position);
}
function updateCameraThirdPerson() {
  camera.position.z += ((mousePos.x * 100) - camera.position.z) / 20;
  camera.position.x += (0 - camera.position.x) / 20;
  camera.position.y += (150 - camera.position.y) / 20;
  camera.rotation.x += (0 - camera.rotation.x) / 20;
  camera.rotation.y += (0 - camera.rotation.y) / 20;
  camera.rotation.z += (0 - camera.rotation.z) / 20;
}
function updateCameraThirdPersonFront() {
  camera.position.z += (airplane.mesh.position.z - camera.position.z) / 20;
  camera.position.x += (airplane.mesh.position.x + 120 - camera.position.x) / 20;
  camera.position.y += (airplane.mesh.position.y - 50 - camera.position.y) / 20;
  camera.lookAt(airplane.mesh.position);
}
function loop() {
  currentTime = Date.now();
  new Date().getTime();
  deltaTime = currentTime - oldTime;
  oldTime = currentTime;

  const snowTime = currentTime * 0.00005;

  land.mesh.rotation.z += 0.005;
  orbit.mesh.rotation.z += 0.001;
  sky.mesh.rotation.z += 0.003;
  forest.mesh.rotation.z += 0.005;
  snow.update(snowTime);

  if (store.state.cameraMode === 'firstPerson') {
    updateCameraFirstPerson();
  }
  if (store.state.cameraMode === 'thirdPerson') {
    updateCameraThirdPerson();
  }
  if (store.state.cameraMode === 'thirdPersonFront') {
    updateCameraThirdPersonFront();
  }

  switch (game.status) {
    default:
    case 'start':
      updatePlane();
      break;
    case 'playing':
      game.levelDistance += 0.6;
      game.planeSpeed = normalize(mousePos.x, -0.5, 0.5, game.planeMinSpeed, game.planeMaxSpeed);
      updatePlane();
      updateEnergy();

      if (Math.floor(game.distance) % game.distanceForCoinsSpawn === 0 && Math.floor(game.distance) > game.coinLastSpawn) {
        game.coinLastSpawn = Math.floor(game.distance);
        coinsHolder.spawnCoins();
      }
      if (Math.floor(game.distance) % game.distanceForEnemiesSpawn === 0 && Math.floor(game.distance) > game.enemyLastSpawn) {
        game.enemyLastSpawn = Math.floor(game.distance);
        enemiesHolder.spawnEnemies();
      }

      if (Math.floor(game.distance) % game.distanceForSpeedUpdate === 0 && Math.floor(game.distance) > game.speedLastUpdate) {
        game.speedLastUpdate = Math.floor(game.distance);
        game.targetBaseSpeed += game.incrementSpeedByTime * deltaTime;
      }
      if (game.levelDistance > game.distanceForLevelUpdate) {
        game.levelLastUpdate = Math.floor(game.distance);
        game.level += 1;
        store.commit('setLevel', game.level);
        game.targetBaseSpeed = game.initSpeed; // + game.incrementSpeedByLevel * game.level;
        game.levelDistance = 0;
      }
      updateDistance();
      // if (Math.floor(game.distance) % game.distanceForSpeedUpdate === 0 && Math.floor(game.distance) > game.speedLastUpdate) {
      //   game.speedLastUpdate = Math.floor(game.distance);
      //   game.targetBaseSpeed += game.incrementSpeedByTime * deltaTime;
      // }
      // if (Math.floor(game.distance) % game.distanceForLevelUpdate === 0 && Math.floor(game.distance) > game.levelLastUpdate) {
      //   game.levelLastUpdate = Math.floor(game.distance);
      //   game.level += 1;
      //   store.commit('setLevel', game.level);
      //   game.targetBaseSpeed = game.initSpeed + game.incrementSpeedByLevel * game.level;
      // }
      break;
    case 'gameover':
      game.speed *= 0.99;
      // airplane.mesh.rotation.z += (-Math.PI / 2 - airplane.mesh.rotation.z) * 0.0002 * deltaTime;
      // airplane.mesh.rotation.x += 0.0003 * deltaTime;
      airplane.mesh.rotation.y += (0 - airplane.mesh.rotation.y) / 3;
      airplane.mesh.rotation.x += (0 - airplane.mesh.rotation.x) / 3;
      airplane.mesh.rotation.z += (0 - airplane.mesh.rotation.z) / 3;
      game.planeFallSpeed *= 1.05;
      airplane.mesh.position.y += game.planeFallSpeed * deltaTime / 2;
      airplane.mesh.position.x += game.planeFallSpeed * deltaTime;

      if (airplane.mesh.position.y > 800) {
        setGameWaiting();
      }
      break;
  }

  updateBanner();

  enemiesHolder.rotateEnemies(deltaTime, airplane, store.state.status);
  coinsHolder.rotateCoins(deltaTime, airplane, store.state.status);

  // store.commit('setTime', currentTime);


  composer.render(0.01);
  // renderer.render(scene, camera);
  game.baseSpeed += (game.targetBaseSpeed - game.baseSpeed) * deltaTime * 0.02;

  game.speed = game.baseSpeed * game.planeSpeed;

  requestAnimationFrame(loop);
}


function handleMouseMove(event) {
  const tx = -1 + (event.clientX / WIDTH) * 2;
  const ty = 1 - (event.clientY / HEIGHT) * 2;
  mousePos = { x: tx, y: ty };
}
function handleTouchMove(e) {
  const tx = -1 + (e.touches[0].clientX / WIDTH) * 2;
  const ty = 1 - (e.touches[0].clientY / HEIGHT) * 2;
  mousePos = { x: tx, y: ty };
}
function handleTouchStart(event) {
  document.addEventListener('touchmove', handleTouchMove, false);
}
function handleTouchEnd(event) {
  document.removeEventListener('touchmove', handleTouchMove, false);
}

function init(_store) {
  store = _store;

  createScene();
  createLights();
  createPlane();
  createBanner();
  createOrbit();
  createSun();
  createLand();
  createForest();
  createSky();
  createSnow();
  createEnemies();
  createParticles();
  createCoins();

  enemiesHolder.on('boom', (enemy) => {
    particlesHolder.spawnParticles(enemy.mesh.position.clone(), 15, Colors.red, 3);
    shadowLight.intensity = 2;
    removeEnergy();
    TweenMax.to(shadowLight, 0.3, {
      intensity: 0.5,
    });
    if (store.state.sound) {
      enemiesHolder.playSound();
    }
  });
  coinsHolder.on('ding', (coin) => {
    addEnergy();
    if (store.state.sound) {
      coinsHolder.playSound();
    }
    particlesHolder.spawnParticles(coin.mesh.position.clone(), 5, Colors.red, 0.8);
  });

  store.watch(() => store.state.level, (newLevel, oldLevel) => {
    if (newLevel !== 1 && store.state.destinationsBuffer.length > 0) {
      store.dispatch('setDestination');
      store.dispatch('setStatus', 'level');
    }
    if (store.state.destinationsBuffer.length === 0) {
      setGameOver();
    }
  });
  store.watch(() => store.state.status, (status, prevStatus) => {

    if (status === 'start') {
      store.commit('setCameraMode', 'firstPerson');
    }
    if (status === 'playing') {
      store.commit('setCameraMode', 'thirdPerson');
      if (prevStatus !== 'level') {
        store.commit('setLevel', 1);
        resetGame();
      }
    }
    if (status === 'gameover') {
      store.dispatch('reset');
      store.commit('setCameraMode', 'thirdPersonFront');
    }
    if (status === 'waitingReplay') {
      store.commit('setCameraMode', 'firstPerson');
    }
    if (status === 'level') {
      store.commit('setCameraMode', 'firstPerson');
      enemiesHolder.destroyAll();
      coinsHolder.destroyAll();
      game.energy = game.energy + 20 < 100 ? game.energy + 20 : 100;
      store.commit('setEnergy', game.energy);
    }
    game.status = status;
  });
  store.watch(() => store.state.orientation, (orientation) => {
    // const tx = -1 + (event.clientX / WIDTH) * 2;
    const tx = 1 + (Math.abs(orientation.gamma)) / 90;
    const ty = (1 - ((orientation.beta - 15) / 90) * 3) * -1;
    mousePos = { x: tx, y: ty };
  });

  document.addEventListener('mousemove', handleMouseMove, false);
  document.addEventListener('touchstart', handleTouchStart, false);
  document.addEventListener('touchend', handleTouchEnd, false);

  // $(document).click(handleMouseMove, false);
  // window.addEventListener('deviceorientation', handleOrientation, true);

  loop();
}

export default {
  init,
};
