import { Colors } from './Settings';

const THREE = require('three');

const AirPlane = function () {
  this.mesh = new THREE.Object3D();
  this.count = 0;
  // Create the cabin - FUSOLIERA
  const geomCockpit = new THREE.CylinderGeometry(25, 10, 100, 8);
  const matCockpit = new THREE.MeshPhongMaterial({ color: Colors.red, flatShading: THREE.FlatShading });
  const cockpit = new THREE.Mesh(geomCockpit, matCockpit);
  cockpit.castShadow = true;
  cockpit.receiveShadow = true;
  cockpit.rotation.z = (Math.PI / 180) * -90;
  this.mesh.add(cockpit);

  // Create the engine - MOTORE
  const geomEngine = new THREE.CylinderGeometry(27, 27, 20, 10);
  const matEngine = new THREE.MeshPhongMaterial({ color: Colors.white, flatShading: THREE.FlatShading });
  const engine = new THREE.Mesh(geomEngine, matEngine);
  engine.position.x = 42;
  engine.rotation.z = (Math.PI / 180) * -90;
  engine.castShadow = true;
  engine.receiveShadow = true;
  this.mesh.add(engine);

  // Create the tail - CODA

  const length = 20;
  const width = 20;

  const shape = new THREE.Shape();
  shape.moveTo(0, 0);
  shape.lineTo(0, width);
  shape.lineTo(length - 6, width - 6);
  shape.lineTo(length, 0);
  shape.lineTo(0, 0);

  const extrudeSettings = {
    steps: 2,
    depth: 3,
    bevelEnabled: false,
    bevelThickness: 1,
    bevelSize: 1,
    bevelSegments: 1,
  };

  const geomTailPlane = new THREE.ExtrudeGeometry(shape, extrudeSettings);

  const matTailPlane = new THREE.MeshPhongMaterial({ color: Colors.white, flatShading: THREE.FlatShading });
  const tailPlane = new THREE.Mesh(geomTailPlane, matTailPlane);
  tailPlane.position.set(-50, 10, -1.5);
  tailPlane.castShadow = true;
  tailPlane.receiveShadow = true;
  this.mesh.add(tailPlane);

  // Create the wing - ALI
  const geomSideWing = new THREE.BoxGeometry(40, 2, 150, 3, 1, 1);

  const loader = new THREE.TextureLoader();
  const textureTop = loader.load(App.LOGO_AEREO);
  textureTop.wrapS = THREE.ClampToEdgeWrapping;
  textureTop.wrapT = THREE.ClampToEdgeWrapping;
  const textureBottom = loader.load(App.LOGO_AEREO_BOTTOM);
  textureBottom.wrapS = THREE.ClampToEdgeWrapping;
  textureBottom.wrapT = THREE.ClampToEdgeWrapping;
  const matSideWingTop = new THREE.MeshPhongMaterial(
    {
      color: Colors.white,
      flatShading: THREE.FlatShading,
      map: textureTop,
      transparent: true,
    },
  );
  const matSideWingBottom = new THREE.MeshPhongMaterial(
    {
      color: Colors.white,
      flatShading: THREE.FlatShading,
      map: textureBottom,
      transparent: true,
    },
  );

  const sideWingTop = new THREE.Mesh(geomSideWing, matSideWingTop);
  const sideWingBottom = new THREE.Mesh(geomSideWing, matSideWingBottom);
  sideWingTop.castShadow = true;
  sideWingTop.receiveShadow = true;
  sideWingBottom.castShadow = true;
  sideWingBottom.receiveShadow = true;

  sideWingTop.position.set(20, 32, 0);
  sideWingBottom.position.set(20, -3, 0);
  this.mesh.add(sideWingTop);
  this.mesh.add(sideWingBottom);

  const geomPaloSmall = new THREE.BoxGeometry(2, 14, 2, 1, 1, 1);
  const matPaloSmall = new THREE.MeshPhongMaterial({ color: Colors.brown, flatShading: THREE.FlatShading });

  const paloSmallDx = new THREE.Mesh(geomPaloSmall, matPaloSmall);
  paloSmallDx.position.set(15, 25, 15);
  paloSmallDx.castShadow = true;
  paloSmallDx.receiveShadow = true;
  paloSmallDx.rotation.x = (Math.PI / 180) * 20;

  this.mesh.add(paloSmallDx);

  const paloSmallSx = new THREE.Mesh(geomPaloSmall, matPaloSmall);
  paloSmallSx.position.set(15, 25, -15);
  paloSmallSx.castShadow = true;
  paloSmallSx.receiveShadow = true;
  paloSmallSx.rotation.x = (Math.PI / 180) * -20;

  this.mesh.add(paloSmallSx);

  // FINESTRINO
  // const geomWindshield = new THREE.BoxGeometry(3, 15, 20, 1, 1, 1);
  // const matWindshield = new THREE.MeshPhongMaterial({
  //   color: Colors.white, transparent: true, opacity: 0.3, flatShading: THREE.FlatShading,
  // });
  // const windshield = new THREE.Mesh(geomWindshield, matWindshield);
  // windshield.position.set(5, 27, 0);
  //
  // windshield.castShadow = true;
  // windshield.receiveShadow = true;
  //
  // this.mesh.add(windshield);

  const geomPropeller = new THREE.BoxGeometry(20, 10, 10, 1, 1, 1);
  geomPropeller.vertices[4].y -= 5;
  geomPropeller.vertices[4].z += 5;
  geomPropeller.vertices[5].y -= 5;
  geomPropeller.vertices[5].z -= 5;
  geomPropeller.vertices[6].y += 5;
  geomPropeller.vertices[6].z += 5;
  geomPropeller.vertices[7].y += 5;
  geomPropeller.vertices[7].z -= 5;
  const matPropeller = new THREE.MeshPhongMaterial({ color: Colors.brown, flatShading: THREE.FlatShading });
  this.propeller = new THREE.Mesh(geomPropeller, matPropeller);
  this.propeller.castShadow = true;
  this.propeller.receiveShadow = true;

  // PALE
  const geomBlade1 = new THREE.BoxGeometry(1, 70, 10, 1, 1, 1);
  const geomBlade2 = new THREE.BoxGeometry(1, 10, 70, 1, 1, 1);
  const matBlade = new THREE.MeshPhongMaterial({ color: Colors.white, flatShading: THREE.FlatShading });

  const blade1 = new THREE.Mesh(geomBlade1, matBlade);
  blade1.position.set(8, 0, 0);
  blade1.castShadow = true;
  blade1.receiveShadow = true;

  const blade2 = new THREE.Mesh(geomBlade2, matBlade);
  blade2.position.set(8, 0, 0);
  blade2.castShadow = true;
  blade2.receiveShadow = true;
  this.propeller.add(blade1, blade2);
  this.propeller.position.set(50, 0, 0);
  this.mesh.add(this.propeller);

  const wheelProtecGeom = new THREE.BoxGeometry(30, 15, 10, 1, 1, 1);
  const wheelProtecMat = new THREE.MeshPhongMaterial({ color: Colors.white, flatShading: THREE.FlatShading });
  const wheelProtecR = new THREE.Mesh(wheelProtecGeom, wheelProtecMat);
  wheelProtecR.position.set(25, -20, 25);
  this.mesh.add(wheelProtecR);

  const wheelTireGeom = new THREE.CylinderGeometry(12, 12, 5, 10);
  const wheelTireMat = new THREE.MeshPhongMaterial({ color: Colors.brownDark, flatShading: THREE.FlatShading });
  const wheelTireR = new THREE.Mesh(wheelTireGeom, wheelTireMat);
  wheelTireR.rotation.x = (Math.PI / 180) * -90;
  wheelTireR.position.set(25, -28, 25);

  const wheelAxisGeom = new THREE.BoxGeometry(10, 10, 6);
  const wheelAxisMat = new THREE.MeshPhongMaterial({ color: Colors.brown, flatShading: THREE.FlatShading });
  const wheelAxis = new THREE.Mesh(wheelAxisGeom, wheelAxisMat);
  wheelTireR.add(wheelAxis);

  this.mesh.add(wheelTireR);

  const wheelProtecL = wheelProtecR.clone();
  wheelProtecL.position.z = -wheelProtecR.position.z;
  this.mesh.add(wheelProtecL);

  const wheelTireL = wheelTireR.clone();
  wheelTireL.position.z = -wheelTireR.position.z;

  this.mesh.add(wheelTireL);

  const wheelTireB = wheelTireR.clone();
  wheelTireB.scale.set(0.5, 0.5, 0.5);
  wheelTireB.position.set(-35, -25, 0);
  this.mesh.add(wheelTireB);

  const suspensionGeom = new THREE.BoxGeometry(4, 20, 4);
  suspensionGeom.applyMatrix(new THREE.Matrix4().makeTranslation(0, 10, 0));
  const suspensionMat = new THREE.MeshPhongMaterial({ color: Colors.red, flatShading: THREE.FlatShading });
  const suspension = new THREE.Mesh(suspensionGeom, suspensionMat);
  suspension.position.set(-35, -25, 0);
  suspension.rotation.z = -0.3;
  this.mesh.add(suspension);
};

export default AirPlane;
