var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cm-sound js-sound",
      class: {
        "cm-sound--is-stopped": !_vm.sound
      },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.handleStart($event)
        }
      }
    },
    [
      _c("img", {
        staticClass: "cm-sound__ico",
        attrs: { src: "assets/imgs/icona-sound.png" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }