import { Howl } from 'howler';
import { Dispatcher } from '../../components/ganimede/dist/ganimede.min';
import { Colors, game } from './Settings';

const THREE = require('three');

const { coinsPool } = game;

export const Coin = function () {
  const materials = [
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-side-2.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-side-2.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-top-2.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-top-2.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-side-2.png'),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load('assets/texture/packet-side-2.png'),
    }),
  ];
  const geom = new THREE.BoxGeometry(10, 10, 10);
  // const mat = new THREE.MeshBasicMaterial({
  //   // color: 0x0099FF,
  //   // shininess: 0,
  //   // specular: 0xffffff,
  //   // envMap: textureCube,
  //   // flatShading: THREE.FlatShading,
  // });
  this.mesh = new THREE.Mesh(geom, materials);
  this.mesh.castShadow = true;
  this.angle = 0;
  this.dist = 0;
};

export class CoinsHolder extends Dispatcher {
  constructor(nCoins, events) {
    super(['ding']);
    this.mesh = new THREE.Object3D();
    this.coinsInUse = [];
    this.coinsPool = [];
    for (let i = 0; i < nCoins; i += 1) {
      const coin = new Coin();
      this.coinsPool.push(coin);
    }
    this.sound = new Howl({
      src: ['./assets/sound-fx/ding.mp3'],
    });
  }

  spawnCoins() {
    const nCoins = 1 + Math.floor(Math.random() * 10);

    const d = game.seaRadius + game.planeDefaultHeight + (-1 + Math.random() * 2) * (game.planeAmpHeight - 20);
    const amplitude = 10 + Math.round(Math.random() * 10);
    for (let i = 0; i < nCoins; i += 1) {
      let coin;
      if (this.coinsPool.length) {
        coin = this.coinsPool.pop();
      } else {
        coin = new Coin();
      }
      this.mesh.add(coin.mesh);
      this.coinsInUse.push(coin);
      coin.angle = -(i * 0.02);
      coin.distance = d + Math.cos(i * 0.5) * amplitude;
      coin.mesh.position.y = -game.seaRadius + Math.sin(coin.angle) * coin.distance;
      coin.mesh.position.x = Math.cos(coin.angle) * coin.distance;
      coin.mesh.position.z = -250;
    }
  }

  rotateCoins(deltaTime, airplane, status) {
    for (let i = 0; i < this.coinsInUse.length; i += 1) {
      const coin = this.coinsInUse[i];
      if (coin.exploding) continue;
      coin.angle += game.speed * deltaTime * game.coinsSpeed;
      if (coin.angle > Math.PI * 2) coin.angle -= Math.PI * 2;
      coin.mesh.position.y = -game.seaRadius + Math.sin(coin.angle) * coin.distance;
      coin.mesh.position.x = 10 + Math.cos(coin.angle) * coin.distance;
      coin.mesh.rotation.z += Math.random() * 0.1;
      coin.mesh.rotation.y += Math.random() * 0.1;

      // var globalCoinPosition =  coin.mesh.localToWorld(new THREE.Vector3());
      const diffPos = airplane.mesh.position.clone().sub(coin.mesh.position.clone());
      const d = diffPos.length();
      if (d < game.coinDistanceTolerance && status === 'playing') {
        this.coinsPool.unshift(this.coinsInUse.splice(i, 1)[0]);
        this.mesh.remove(coin.mesh);
        this.emit('ding', coin);
        i -= 1;
      } else if (coin.angle > Math.PI) {
        this.coinsPool.unshift(this.coinsInUse.splice(i, 1)[0]);
        this.mesh.remove(coin.mesh);
        i -= 1;
      }
    }
  }

  playSound() {
    this.sound.play();
  }

  destroyAll() {
    for (let i = 0; i < this.coinsInUse.length; i += 1) {
      const coin = this.coinsInUse[i];
      this.coinsPool.unshift(this.coinsInUse.splice(i, 1)[0]);
      this.mesh.remove(coin.mesh);
    }
    this.mesh.children.forEach((item) => {
      this.mesh.remove(item);
    });
  }
}
